import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface LoadingState {
    loading : boolean,
    isProgress : boolean,
    progress : number,
    progressBarMessage : string,
    opacity : number
}

const defaultOpacity = 0.5;

const initialState: LoadingState = {
    loading : false,
    isProgress : false,
    progress : 0,
    progressBarMessage : '',
    opacity : defaultOpacity
}

export type LoadingSet = {
    show : boolean,
    opacity? : number
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<LoadingSet>) => {
            state.loading = action.payload.show
            state.opacity = !!action.payload.opacity?(action.payload.opacity):defaultOpacity;
            state.isProgress = false;
            state.progress = 0;
            state.progressBarMessage = ''
        },
        setLoadingWithProgress : (state, action : PayloadAction<boolean>)=>{
            state.loading = action.payload;
            state.isProgress = action.payload;
        },
        setProgress: (state, action: PayloadAction<number>) => {
            state.progress = action.payload
        },
        setProgressMessage: (state, action: PayloadAction<string>) => {
            state.progressBarMessage = action.payload
        }
    },
})

export const { setLoading, setLoadingWithProgress, setProgress, setProgressMessage } = loadingSlice.actions

export const selectLoading = (state: RootState) => state.loading.loading

export default loadingSlice.reducer
