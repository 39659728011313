import { useInjection } from 'inversify-react';
import React from 'react';
import Book from '../../../models/dod/books/Book';
import { IAuthService } from '../../../services/IAuthService';

interface BookInfoProps {
    book: Book;
    update: (book: Book) => void;
}

export default function BookInfo(props: BookInfoProps) {
    const authService = useInjection<IAuthService>('IAuthService');

    const dataAccess = authService.getAccessLevel('data');

    const { book, update } = props;

    const onBookSizeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        const { value } = e.target;
        book.size = parseInt(value);
        update(book);
    };

    const getInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        return e.target.value;
    };

    const onDevAccessChanged = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {checked} = e.target;
        book.access = checked?0:1;
        update(book);
    }

    return (
        <div className="book_info" id="book_info">
            <div className="book_entry">
                <span className="book_label">Book Name</span>
                <input
                    type="text"
                    className="book_name"
                    id="book_name"
                    value={book.name}
                    onChange={(e) => {
                        book.name = getInputValue(e);
                        update(book);
                    }}
                ></input>
            </div>
            <div className="book_entry">
                <span className="book_label">Category</span>
                <input
                    type="text"
                    className="book_category"
                    id="book_category"
                    value={book.category}
                    onChange={(e) => {
                        book.category = getInputValue(e);
                        update(book);
                    }}
                ></input>
            </div>
            <div className="book_entry">
                <span className="book_label">Book Type</span>
                <select
                    className="book_size"
                    id="book_size"
                    onChange={onBookSizeChanged}
                >
                    <option value="0">Soft-Cover Small</option>
                    <option value="1">Soft-Cover Large</option>
                    <option value="2">Hard-Cover</option>
                </select>
            </div>
            {dataAccess >= 3 ? (
                <div className="book_entry">
                    <span className="book_label">Jr Dev Access</span>
                    <input
                        type="checkbox"
                        className="dev_access"
                        id="dev_access"
                        checked={book.access === 0}
                        onChange={onDevAccessChanged}
                    ></input>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
