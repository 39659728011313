import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import classes from '../../../utils/classes'
import conditionalClass from '../../../utils/conditional-class'
interface HeaderButtonProps {
    iconId: string
    name: string
    target_path: string
}

export default function HeaderButton(props: HeaderButtonProps) {
    const location = useLocation();
    const selected = location.pathname.endsWith(props.target_path);
    return (
        <div
            className={classes(
                'item-group',
                conditionalClass(selected, 'current', '')
            )}
        >
            <div className={classes('icon', props.iconId)}></div>
            <Link to={props.target_path} className="item" target="_self">
                {props.name}
            </Link>
        </div>
    )
}
