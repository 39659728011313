import axios from "axios";
import { inject, injectable } from "inversify";
import { IAuthService } from "../../services/IAuthService";
import IGDMRESTClient from "../IGDMRESTClient";
import { RESTClient } from "./RESTClient";

@injectable()
export default class GDMRESTClient extends RESTClient implements IGDMRESTClient {

    @inject('IAuthService')
    private readonly authService!: IAuthService;

    private readonly baseURLv2: string;

    constructor() {
        super();
        this.setBaseURL('https://wod-gdm-uux56memxa-uc.a.run.app/api/v1/');
        this.baseURLv2 = 'https://wod-gdm-uux56memxa-uc.a.run.app/api/v2/';
    }

    protected getFullURLV2(
        url: string,
        params: Map<string, string> = new Map<string, string>()
    ): string {
        let targetUrl = this.baseURLv2 + url;
        let currentParam = 0;
        params.forEach((value: string, key: string) => {
            if (currentParam === 0) {
                targetUrl += '?';
            } else targetUrl += '&';

            targetUrl += key;
            targetUrl += '=';
            targetUrl += value;

            currentParam++;
        });
        return targetUrl;
    }

    async breakCompatibility(
        pipeline: string
    ): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURLV2(
            `saves/bulk/pipeline/${pipeline}`
        );

        try {
            await axios.put(path, null, this.getHeader(devopsToken));
            return true;
        } catch {
            return false;
        }
    }
}