import { inject, injectable } from 'inversify';
import IResourceModel from '../../models/dod/IResourceModel';
import LimitedSizeMap from '../../models/LimitedSizeMap';
import type IGoogleStorageRESTClient from '../../rest-clients/IGoogleStorageRESTClient';
import type IImageThumbRESTClient from '../../rest-clients/IImageThumbRESTClient';
import ITextureRegistry from '../ITextureRegistry';

@injectable()
export default class TextureRegistry implements ITextureRegistry {
    @inject('IGoogleStorageRESTClient')
    private readonly googleStorageRestClient!: IGoogleStorageRESTClient;

    @inject('IImageThumbRESTClient')
    private readonly imageThumbRestClient!: IImageThumbRESTClient;

    private idToFileNameMap: Map<number, string>;
    private fileNameToIdMap: Map<string, number>;
    private missingTextures: Set<number>;
    private idToImageDataMap: Map<number, string>;
    private originalItems: IResourceModel[];
    private dataCallbacks: Map<number, (data: string | null) => void>;
    private bufferOfFullSizeData: LimitedSizeMap<string, string>;
    private pendingImageRequests: Map<string, ((data: string | null) => void)[]>;

    constructor() {
        this.idToFileNameMap = new Map<number, string>();
        this.fileNameToIdMap = new Map<string, number>();
        this.missingTextures = new Set<number>();
        this.idToImageDataMap = new Map<number, string>();
        this.dataCallbacks = new Map<number, (data: string | null) => void>();
        this.bufferOfFullSizeData = new LimitedSizeMap<string, string>(3);
        this.pendingImageRequests = new Map<string, ((data: string|null)=>void)[]>();
        this.originalItems = [];
    }

    put(items: IResourceModel[]): void {
        this.originalItems = items;

        this.fileNameToIdMap.clear();
        this.idToFileNameMap.clear();
        this.missingTextures.clear();

        this.idToFileNameMap.set(0, 'None');

        items.forEach((r) => {
            const fileName = r.resource_path.slice(
                r.resource_path.indexOf('/') + 1
            );
            this.fileNameToIdMap.set(fileName, r.id);
            this.idToFileNameMap.set(r.id, fileName);
        });
    }

    getIdToFileNameMap(): Map<number, string> {
        return this.idToFileNameMap;
    }

    loadTexturesFromCloud(
        getDirectoryURL: (item: string) => string
    ): Promise<boolean> {
        this.dataCallbacks.clear();

        const downloadPromise = new Promise<boolean>((resolve, reject) => {
            const itemsCount = this.originalItems.length;
            let downloadedItems = 0;

            const downloadHandler = (id: number, value: string | null) => {
                downloadedItems++;

                if (value === null) {
                    this.missingTextures.add(id);
                } else {
                    this.idToImageDataMap.set(id, value);
                }

                if (this.dataCallbacks.has(id)) {
                    this.dataCallbacks.get(id)!(value);
                }

                if (downloadedItems === itemsCount) {
                    resolve(true);
                }
            };

            const map = new Map<number, string>();
            this.originalItems.forEach((item) => {
                map.set(item.id, getDirectoryURL(item.resource_path));
            });

            this.googleStorageRestClient.getTextureBase64Batch(
                map,
                (id, data) => {
                    downloadHandler(id, data);
                }
            );
        });

        return downloadPromise;
    }

    loadTexturesFromCloudThumbnails(
        getDirectoryURL: (item: string) => string
    ): Promise<boolean> {
        this.dataCallbacks.clear();

        const downloadPromise = new Promise<boolean>((resolve, reject) => {
            const itemsCount = this.originalItems.length;
            let downloadedItems = 0;

            const downloadHandler = (id: number, value: string | null) => {
                downloadedItems++;

                if (value === null) {
                    this.missingTextures.add(id);
                } else {
                    this.idToImageDataMap.set(id, value);
                }

                if (this.dataCallbacks.has(id)) {
                    this.dataCallbacks.get(id)!(value);
                }

                if (downloadedItems === itemsCount) {
                    resolve(true);
                }
            };

            const map = new Map<number, string>();
            this.originalItems.forEach((item) => {
                map.set(item.id, getDirectoryURL(item.resource_path));
            });

            this.imageThumbRestClient.getTextureBase64Batch(map, (id, data) => {
                downloadHandler(id, data);
            });
        });

        return downloadPromise;
    }

    getTextureData(id: number): string | null {
        const data = this.idToImageDataMap.get(id);

        if (data) return data;

        return null;
    }

    getTextureDataCallback(
        id: number,
        dataCallback: (data: string | null) => void
    ): string | null {
        if (this.missingTextures.has(id)) {
            return null;
        }

        if (this.idToImageDataMap.has(id)) {
            return this.idToImageDataMap.get(id)!;
        }

        this.dataCallbacks.set(id, dataCallback);
        return null;
    }

    isMissingTexture(id: number): boolean {
        return this.missingTextures.has(id);
    }

    getTextureDataCallbackSingleBuffer(
        resourcePath: string,
        dataCallback: (data: string | null) => void
    ): string | null {

        if (this.bufferOfFullSizeData.has(resourcePath)) {
            dataCallback(this.bufferOfFullSizeData.get(resourcePath)!);
            return this.bufferOfFullSizeData.get(resourcePath)!;
        }

        if(this.pendingImageRequests.has(resourcePath)){
            this.pendingImageRequests.get(resourcePath)!.push(dataCallback);
        }
        else{
            this.pendingImageRequests.set(resourcePath, [dataCallback])
        }

        this.googleStorageRestClient
            .getTextureBase64(resourcePath)
            .then((data) => {
                if(this.pendingImageRequests.has(resourcePath)){
                    for(let callback of this.pendingImageRequests.get(resourcePath)!){
                        callback(data);
                    }
                }
                this.pendingImageRequests.delete(resourcePath);
                if (data !== null)
                    this.bufferOfFullSizeData.set(resourcePath, data);
            });

        return null;
    }

    hasPendingImageRequests() : Promise<boolean> {
        const promise =  new Promise<boolean>((resolve,reject)=>{
            if(this.pendingImageRequests.size===0){
                resolve(true);
            }
            else{
                const interval = setInterval(()=>{
                    if(this.pendingImageRequests.size===0){
                        clearInterval(interval);
                        resolve(true);
                    }
                }, 100)
            }
        })

        return promise;
    }
}
