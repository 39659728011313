import { useInjection } from 'inversify-react';
import React, { useEffect } from 'react';
import Book from '../../../models/dod/books/Book';
import BookPage from '../../../models/dod/books/BookPage';
import { IAuthService } from '../../../services/IAuthService';
import IBookService from '../../../services/IBookService';
import IZoomService from '../../../services/IZoomService';
import classes from '../../../utils/classes';
import BookPageToolbar from '../book-page-toolbar/book-page-toolbar.component';
import BookSwapTexture from '../book-swap-page-texture/book-swap-texture.component';

interface BookFormProps {
    book: Book;
    update: (book: Book) => void;
    swapBookCover : (side:string, file : File)=>void;
    swapPage : (pageId:number, file : File)=>void;
    getImagePath : (id:number)=>string|null;
    pageNumber : number,
    setPageNumber : (page:number)=>void
}

export default function BookForm(props: BookFormProps) {
    const authService = useInjection<IAuthService>('IAuthService');
    const bookService = useInjection<IBookService>('IBookService');
    const zoomService = useInjection<IZoomService>('IZoomService');

    const dataAccess = authService.getAccessLevel('data');

    const { book, update,swapBookCover,swapPage, getImagePath,pageNumber, setPageNumber } = props;

    const textureClassName = bookService.getBookPageTextureClass(
        props.book.size
    );

    useEffect(()=>{
        setPageNumber(0);
    }, [setPageNumber, book.id]);

    const insertPage = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        const bookPage: BookPage = {
            id: 0,
            resource_id: 0,
            page_number: -1,
            book_id: book.id,
        };

        book.pages.splice(pageNumber + 1, 0, bookPage);

        update(book);
    };

    return (
        <React.Fragment>
            <h3>Pages</h3>
            <div className="book_list_container">
                <div
                    className={classes('book_display', textureClassName)}
                    id="book_display"
                >
                    <div className="cover-entry">
                        <BookSwapTexture
                            dataAccess={dataAccess}
                            sideClass="cover-front"
                            swapClass="front"
                            classSize={textureClassName}
                            headerText="Front Cover"
                            texture_id={book.front_id}
                            textureEntryClass="cover-front"
                            deleteEnabled={false}
                            onSelectedChanged={(id) => {
                                book.front_id = id;
                                update(book);
                            }}
                            swapTexture={(f) => swapBookCover('front', f)}
                            zoom={()=>{
                                zoomService.zoom(getImagePath(book.front_id),textureClassName);
                            }}
                        />
                    </div>

                    <div className="book_texture_entry">
                        <div className="book_textures">
                            {book.pages[pageNumber] && (
                                <BookSwapTexture
                                    dataAccess={dataAccess}
                                    sideClass="page-left"
                                    swapClass="left"
                                    classSize={textureClassName}
                                    headerText={`Page ${pageNumber + 1}`}
                                    texture_id={
                                        book.pages[pageNumber].resource_id
                                    }
                                    textureEntryClass="page-left"
                                    deleteEnabled={true}
                                    deleteClass="left"
                                    onSelectedChanged={(id) => {
                                        book.pages[pageNumber].resource_id = id;
                                        update(book);
                                    }}
                                    delete={() => {
                                        book.pages.splice(pageNumber, 1);
                                        update(book);
                                    }}
                                    swapTexture={(f) => swapPage(book.pages[pageNumber].id,f)}
                                    zoom={()=>{
                                        zoomService.zoom(getImagePath(book.pages[pageNumber].resource_id),textureClassName);
                                    }}
                                />
                            )}
                            {book.pages[pageNumber + 1] && (
                                <BookSwapTexture
                                    dataAccess={dataAccess}
                                    sideClass="page-right"
                                    swapClass="right"
                                    classSize={textureClassName}
                                    headerText={`Page ${pageNumber + 2}`}
                                    texture_id={
                                        book.pages[pageNumber + 1].resource_id
                                    }
                                    textureEntryClass="page-right"
                                    deleteEnabled={true}
                                    deleteClass="right"
                                    onSelectedChanged={(id) => {
                                        book.pages[pageNumber + 1].resource_id =
                                            id;
                                        update(book);
                                    }}
                                    delete={() => {
                                        book.pages.splice(pageNumber + 1, 1);
                                        update(book);
                                    }}
                                    swapTexture={(f) => swapPage(book.pages[pageNumber+1].id,f)}
                                    zoom={()=>{
                                        zoomService.zoom(getImagePath(book.pages[pageNumber+1].resource_id),textureClassName);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="cover-entry">
                        <BookSwapTexture
                            dataAccess={dataAccess}
                            sideClass="cover-back"
                            swapClass="back"
                            classSize={textureClassName}
                            headerText="Back Cover"
                            texture_id={book.back_id}
                            textureEntryClass="cover-back"
                            deleteEnabled={false}
                            onSelectedChanged={(id) => {
                                book.back_id = id;
                                update(book);
                            }}
                            swapTexture={(f) => swapBookCover('back', f)}
                            zoom={()=>{
                                zoomService.zoom(getImagePath(book.back_id),textureClassName);
                            }}
                        />
                    </div>
                </div>
            </div>
            <BookPageToolbar
                page={pageNumber}
                totalPages={book.pages.length}
                changePage={setPageNumber}
            />
            {dataAccess >= 2 && (
                <input
                    type="button"
                    value="Insert Page Here"
                    className="insert-page"
                    onClick={insertPage}
                ></input>
            )}
        </React.Fragment>
    );
}
