import { useInjection } from 'inversify-react';
import React from 'react';
import TokenModel from '../../../models/dod/tokens/TokenModel';
import { IAuthService } from '../../../services/IAuthService';
import TokenHeader from '../token-header/token-header.component';
import SwapTexture from '../../general/swap-texture/swap-texture.component';
import ITokenService from '../../../services/ITokenService';

interface TokenFormProps {
    token: TokenModel | null;
    refresh: () => void;
    update: (model: TokenModel) => void;
    delete: () => void;
    save: () => void;
    saveEnabled: boolean;
    swapTexture:(side : string, file:File)=>void;
}

export default function TokenFormComponent(props: TokenFormProps) {
    const authService = useInjection<IAuthService>('IAuthService');
    const tokenService = useInjection<ITokenService>('ITokenService');

    const { token, update, swapTexture } = props;
    if (token === null) return null;

    const dataAccess = authService.getAccessLevel('data');

    const onTokenSizeChanged = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        event.preventDefault();
        const { value } = event.target;
        token.size = parseInt(value);
        update(token);
    };
    
    const inputChangeHandle = (
        event: React.ChangeEvent<HTMLInputElement>,
        transformer: (m: TokenModel, s: string) => void
    ) => {
        event.preventDefault();
        const value = event.target.value;
        transformer(token, value);
        update(token);
    };

    const onDevAccessChanged = (event: React.ChangeEvent<HTMLInputElement>)=>{
        const {checked} = event.target;
        token.access = checked?0:1;
        update(token);
    }

    const renderToolbar = () => {
        return (
            <TokenHeader
                dataAccess={dataAccess}
                saveEnabled={props.saveEnabled}
                delete={props.delete}
                save={props.save}
                refresh={props.refresh}
            />
        );
    };


    const renderTokenInfo = () => {
        return (
            <div className="token_info" id="token_info">
                <div className="token_entry">
                    <span className="token_label">Token Name</span>
                    <input
                        type="text"
                        className="token_name"
                        id="token_name"
                        value={token.name}
                        onChange={(e) => {
                            inputChangeHandle(e, (m, s) => (m.name = s));
                        }}
                    ></input>
                </div>
                <div className="token_entry">
                    <span className="token_label">Category</span>
                    <input
                        type="text"
                        className="token_category"
                        id="token_category"
                        value={token.category}
                        onChange={(e) => {
                            inputChangeHandle(e, (m, s) => (m.category = s));
                        }}
                    ></input>
                </div>
                <div className="token_entry">
                    <span className="token_label">Token Type</span>
                    <select
                        className="token_size"
                        id="token_size"
                        onChange={onTokenSizeChanged}
                        value={token.size}
                    >
                        <option value="0">Circle</option>
                        <option value="1">Rect 1x1</option>
                        <option value="2">Rect 2x2</option>
                        <option value="3">Rect 2x3</option>
                        <option value="4">Rect 3x3</option>
                        <option value="5">Rect 1x2</option>
                        <option value="6">Rect 1x4</option>
                        <option value="7">Blood</option>
                        <option value="8">Cognition</option>
                        <option value="9">Settlement Location</option>
                    </select>
                </div>
                {dataAccess >= 3 ? (
                    <div className="token_entry">
                        <span className="token_label">Jr Dev Access</span>
                        <input
                            type="checkbox"
                            className="dev_access"
                            id="dev_access"
                            checked={token.access === 0}
                            onChange={onDevAccessChanged}
                        ></input>
                    </div>
                ) : null}
            </div>
        );
    };

    const classSize = tokenService.getTextureClass(token.size);

    const renderTextures = () => {
        return (
            <React.Fragment>
                <h3>Textures</h3>
                <div className="token_list_container">
                    <div className="token_list" id="token_list">
                        <div className="token_texture_entry">
                            <div className="token_textures">
                                <SwapTexture
                                    dataAccess={dataAccess}
                                    side="front"
                                    classSize={classSize}
                                    headerText="Front"
                                    texture_id={token.front_id}
                                    onSelectedChanged={(v)=>{
                                        token.front_id = v;
                                        update(token);
                                    }}
                                    swapTexture={swapTexture}
                                />
                                <SwapTexture
                                    dataAccess={dataAccess}
                                    side="back"
                                    classSize={classSize}
                                    headerText="Back"
                                    texture_id={token.back_id}
                                    onSelectedChanged={(v)=>{
                                        token.back_id = v;
                                        update(token);
                                    }}
                                    swapTexture={swapTexture}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <form id="token-form">
            <div style={{ textAlign: 'center', minWidth: '1140px' }}>
                <h3>Token Info</h3>
                {renderToolbar()}
                {renderTokenInfo()}
                {renderTextures()}
            </div>
        </form>
    );
}
