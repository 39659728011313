import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { inject, injectable } from 'inversify';
import type { IAuthService } from '../../services/IAuthService';
import IDoDRESTClient from '../IDoDRESTClient';
import { RESTClient } from './RESTClient';
import TokenList from '../../models/dod/tokens/TokenList';
import TokenModel from '../../models/dod/tokens/TokenModel';
import DeckList from '../../models/dod/decks/DeckList';
import Deck from '../../models/dod/decks/Deck';
import { ErrorMessage } from '../../models/ErrorMessage';
import BookList from '../../models/dod/books/BookList';
import Book from '../../models/dod/books/Book';
import CardSearchResponse from '../../modules/record-sheets-module/models/in-game-cards/CardSearchResponse';
import CardSearchRequest from '../../modules/record-sheets-module/models/in-game-cards/CardSearchRequest';

@injectable()
export default class DoDRESTClient
    extends RESTClient
    implements IDoDRESTClient
{
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    private readonly base2Url =
        'https://wod-dod-uux56memxa-uc.a.run.app/api/v2/';

    constructor() {
        super();
        this.setBaseURL('https://wod-dod-uux56memxa-uc.a.run.app/api/v1/');
    }

    

    async getTokenList(page: number): Promise<TokenList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(
            `token`,
            new Map<string, string>([['page', page.toString()]])
        );

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            return response.data as TokenList;
        } catch {
            return null;
        }
    }

    async getTokenById(tokenId: number): Promise<TokenModel | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`token/${tokenId}`);

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            return response.data as TokenModel;
        } catch {
            return null;
        }
    }

    async updateToken(tokenModel: TokenModel): Promise<ErrorMessage> {
        const token = await this.authService.getToken();
        if (token === null) return { value: '' };

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`token`);

        try {
            const json = JSON.stringify(tokenModel);

            await axios.put(
                path,
                json,
                this.getHeaderJson(this.getHeader(dodToken))
            );

            return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.code === '409')
                    return {
                        value: 'Someone updated the token!',
                        statusCode: 409,
                    };
            }

            return { value: 'Failed to update the token', statusCode: -1 };
        }
    }

    async deleteToken(tokenId: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`token/${tokenId}`);

        const formData = new FormData();
        formData.append('assertion', token);

        try {
            await axios.delete(path, this.getHeaderAndBody(dodToken, formData));
            return true;
        } catch {
            return false;
        }
    }

    async uploadToken(tokenName: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`token`);

        const formData = new FormData();
        formData.append('assertion', token);
        formData.append('tokenName', tokenName);

        try {
            await axios.post(path, formData, this.getHeader(dodToken));
            return true;
        } catch {
            return false;
        }
    }

    async swapTokenTexture(
        tokenId: number,
        side: string,
        textureName: string
    ): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`token/${tokenId}/texture/${textureName}`);

        const formData = new FormData();
        formData.append('assertion', token);
        formData.append('side', side);

        try {
            await axios.put(path, formData, this.getHeader(dodToken));
            return true;
        } catch {
            return false;
        }
    }

    async getDeckList(page: number): Promise<DeckList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(
            `deck`,
            new Map<string, string>([['page', page.toString()]])
        );

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            return response.data as DeckList;
        } catch {
            return null;
        }
    }

    async getDeck(id: number): Promise<Deck | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`deck/${id}`);

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            return response.data as Deck;
        } catch {
            return null;
        }
    }

    async uploadDeck(deckName: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`deck`);

        const formData = new FormData();
        formData.append('assertion', token);
        formData.append('deckName', deckName);

        try {
            await axios.post(path, formData, this.getHeader(dodToken));
            return true;
        } catch {
            return false;
        }
    }

    async deleteDeck(deckId: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`deck/${deckId}`);

        const formData = new FormData();
        formData.append('assertion', token);

        try {
            await axios.delete(path, this.getHeaderAndBody(dodToken, formData));
            return true;
        } catch {
            return false;
        }
    }

    async updateDeck(deck: Deck): Promise<ErrorMessage> {
        const token = await this.authService.getToken();
        if (token === null) return { value: 'Unable to authorize the user!' };

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`deck`);

        try {
            const json = JSON.stringify(deck);

            await axios.put(
                path,
                json,
                this.getHeaderJson(this.getHeader(dodToken))
            );

            return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.code === '409')
                    return {
                        value: 'Someone updated the deck!',
                        statusCode: 409,
                    };
            }

            return { value: 'Failed to update the deck', statusCode: -1 };
        }
    }

    async swapCardTexture(
        cardId: number,
        side: string,
        textureName: string
    ): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`card/${cardId}/texture/${textureName}`);

        const formData = new FormData();
        formData.append('assertion', token);
        formData.append('side', side);

        try {
            await axios.put(path, formData, this.getHeader(dodToken));
            return true;
        } catch {
            return false;
        }
    }

    async getBookList(page: number): Promise<BookList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(
            `book`,
            new Map<string, string>([['page', page.toString()]])
        );

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            const data = response.data as BookList;
            const dataNew = new BookList();
            dataNew.items = data.items;
            dataNew.max_results = data.max_results;
            return dataNew;
        } catch {
            return null;
        }
    }

    async getBook(id: number): Promise<Book | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`book/${id}`);

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            const book = response.data as Book;
            book.pages.sort((a, b) => a.page_number - b.page_number);
            return book;
        } catch {
            return null;
        }
    }

    async deleteBook(id: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`book/${id}`);

        const formData = new FormData();
        formData.append('assertion', token);

        try {
            await axios.delete(path, this.getHeaderAndBody(dodToken, formData));
            return true;
        } catch {
            return false;
        }
    }

    async uploadBook(bookName: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`book`);

        const formData = new FormData();
        formData.append('assertion', token);
        formData.append('bookName', bookName);

        try {
            await axios.post(path, formData, this.getHeader(dodToken));
            return true;
        } catch {
            return false;
        }
    }

    async updateBook(book: Book): Promise<ErrorMessage> {
        const token = await this.authService.getToken();
        if (token === null) return { value: 'Unable to authorize the user!' };

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`book`);

        try {
            const json = JSON.stringify(book);

            await axios.put(
                path,
                json,
                this.getHeaderJson(this.getHeader(dodToken))
            );

            return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.code === '409')
                    return {
                        value: 'Someone updated the book!',
                        statusCode: 409,
                    };
            }

            return { value: 'Failed to update the book', statusCode: -1 };
        }
    }

    async swapBookTextureCover(
        bookId: number,
        side: string,
        textureName: string
    ): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`book/${bookId}/texture/${textureName}`);

        const formData = new FormData();
        formData.append('assertion', token);
        formData.append('type', 'cover');
        formData.append('side', side);

        try {
            await axios.put(path, formData, this.getHeader(dodToken));
            return true;
        } catch {
            return false;
        }
    }

    async swapBookTexturePage(
        bookId: number,
        pageId: number,
        textureName: string
    ): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`book/${bookId}/texture/${textureName}`);

        const formData = new FormData();
        formData.append('assertion', token);
        formData.append('type', 'page');
        formData.append('page_id', pageId.toString());

        try {
            await axios.put(path, formData, this.getHeader(dodToken));
            return true;
        } catch {
            return false;
        }
    }

    async deleteTempFolder(): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`storage/folder`);

        const formData = new FormData();
        formData.append('assertion', token);
        try {
            const header = this.getHeader(dodToken);
            const config: AxiosRequestConfig = {
                ...header,
                data: formData,
            };
            await axios.delete(path, config);
            return true;
        } catch {
            return false;
        }
    }

    async searchForCard(value : string) : Promise<CardSearchResponse |  null>{
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`cards/search`);

        const formData = new FormData();
        formData.append('assertion', token);
        try {
            const header = this.getHeaderJson(this.getHeader(dodToken));
            const form : CardSearchRequest = {
                dispayNames : [
                    value
                ]
            };
            const searchRequest = JSON.stringify(form)
            const response = await axios.post(path, searchRequest, header);
            return response.data as CardSearchResponse;
        } catch {
            return null;
        }
    }

    protected getFullURLV2(
        url: string,
        params: Map<string, string> = new Map<string, string>()
    ): string {
        let targetUrl = this.base2Url + url;
        let currentParam = 0;
        params.forEach((value: string, key: string) => {
            if (currentParam === 0) {
                targetUrl += '?';
            } else targetUrl += '&';

            targetUrl += key;
            targetUrl += '=';
            targetUrl += value;

            currentParam++;
        });
        return targetUrl;
    }
}
