import { useInjection } from 'inversify-react';
import React from 'react';
import Deck from '../../../models/dod/decks/Deck';
import { IAuthService } from '../../../services/IAuthService';
import ITextureRegistry from '../../../services/ITextureRegistry';
import CardList from '../card-list/card-list.component';

interface DeckCardListProps {
    deck: Deck;
    update: (deck: Deck) => void;
    swapCardTexture : (cardId : number, side : string, file : File)=>void;
}

export default function DeckCardList(props: DeckCardListProps) {
    const authService = useInjection<IAuthService>('IAuthService');
    const textureRegistry = useInjection<ITextureRegistry>('ITextureRegistry');

    const dataAccess = authService.getAccessLevel('data');


    const { deck, update } = props;

    const getInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        return e.target.value;
    };

    const onSizeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        deck.size = parseInt(e.target.value);
        update(deck);
    };

    const onCardBackChanged = (e: React.ChangeEvent<HTMLSelectElement>)=>{
        e.preventDefault();
        const {value} = e.target;
        deck.cards.forEach(card=>
            card.back_id = parseInt(value)
        );
        update(deck);
    }

    const onDevAccessChanged = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {checked} = e.target;
        deck.access = checked?0:1;
        update(deck);
    }

    const resources = [...textureRegistry.getIdToFileNameMap()]

    return (
        <React.Fragment>
            <div className="deck_info" id="deck_info">
                <div className="deck_entry">
                    <span className="deck_label">Deck Name</span>
                    <input
                        type="text"
                        className="deck_name"
                        id="deck_name"
                        value={deck.name}
                        onChange={(e) => {
                            deck.name = getInputValue(e);
                            update(deck);
                        }}
                    ></input>
                </div>
                <div className="deck_entry">
                    <span className="deck_label">Category</span>
                    <input
                        type="text"
                        className="deck_category"
                        id="deck_category"
                        value={deck.category}
                        onChange={(e) => {
                            deck.category = getInputValue(e);
                            update(deck);
                        }}
                    ></input>
                </div>
                <div className="deck_entry">
                    <span className="deck_label">Deck Size</span>
                    <select
                        className="deck_size"
                        id="deck_size"
                        value={deck.size}
                        onChange={onSizeChanged}
                    >
                        <option value="0">Small</option>
                        <option value="1">Standard</option>
                        <option value="2">Large</option>
                        <option value="3">Encounter</option>
                        <option value="4">Knowledge</option>
                    </select>
                </div>

                <div className="deck_entry">
                    <span className="deck_label">Default Card Back</span>
                    <select
                        className="deck_back"
                        id="deck_back"
                        value='0'
                        onChange={onCardBackChanged}
                    >
                        {
                            resources.map(value=>{
                                const id = value[0];
                                const fileName = value[1];
                                return (
                                    <option key={id} value={id}>
                                        {fileName}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
                {dataAccess >= 3 ? (
                    <div className="deck_entry">
                        <span className="deck_label">Jr Dev Access</span>
                        <input
                            type="checkbox"
                            className="dev_access"
                            id="dev_access"
                            checked={deck.access === 0}
                            onChange={onDevAccessChanged}
                        ></input>
                    </div>
                ) : null}
            </div>
            <CardList deck={deck} updatedListElement={update} swapTexture={props.swapCardTexture}/>
        </React.Fragment>
    );
}
