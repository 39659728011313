import React, { useState } from 'react';
import conditionalClass from '../../../utils/conditional-class';

interface BookCreateButtonProps {
    createBook: (name: string, files: File[]) => void;
}

export default function BookCreateButton(props: BookCreateButtonProps) {
    const [name, setName] = useState('');

    const bookNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setName(event.target.value);
    };

    const fileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = event.target;
        if(files===null)
            return;
        const filesArray : File[] = []
        for(let index= 0; index<files.length; index++){
            const currentFile = files[index];
            if(currentFile===null) continue;
            filesArray.push(currentFile);
        }
        props.createBook(name, filesArray);
        setName('');
        event.target.files = null;
        event.target.value = ''
    };

    return (
        <div id="create_book">
        <input
            type="text"
            id="create_book_name"
            name="create_book_name"
            placeholder="New book name..."
            value={name}
            onChange={bookNameChange}
        ></input>
        <label id="confirm_book_creation" 
        className={conditionalClass(name.length <= 0, 'disabled', '')}>
            <input
                type="file"
                accept=".jpg, .jpeg, .png"
                multiple
                onChange={fileChanged}
            ></input>
        </label>
    </div>
    );
}
