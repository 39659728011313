import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import ITextureRegistry from '../../../services/ITextureRegistry';
import classes from '../../../utils/classes';
import conditionalClass from '../../../utils/conditional-class';

interface SwapTextureProps {
    dataAccess: number;
    sideClass: string;
    swapClass: string;
    classSize: string;
    headerText: string;
    texture_id: number;
    textureEntryClass?: string;
    deleteEnabled: boolean;
    deleteClass?: string;
    onSelectedChanged: (newId: number) => void;
    swapTexture: (file: File) => void;
    delete?: () => void;
    zoom? : ()=>void;
}

export default function BookSwapTexture(props: SwapTextureProps) {
    const textureRegistry = useInjection<ITextureRegistry>('ITextureRegistry');

    const {
        dataAccess,
        sideClass,
        swapClass,
        headerText,
        texture_id: id,
        textureEntryClass,
        deleteEnabled,
        deleteClass,
        zoom
    } = props;

    const [flag, setFlag] = useState(false);

    const resources = [...textureRegistry.getIdToFileNameMap()];

    const onTextureChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        props.onSelectedChanged(parseInt(event.target.value));
    };

    const onSwapTexture = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        var files = event.target.files;
        if (files === null) return;
        const file = files[0];
        props.swapTexture(file);
        event.target.value = '';
        event.target.files = null;
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>)=>{
        e.preventDefault();
        if(zoom && id!==0){
            zoom();
        }
    }

    const isMissing = textureRegistry.isMissingTexture(id);
    const data = textureRegistry.getTextureDataCallback(id, (data) => {
        setFlag(!flag);
    });

    let isLoading = false;

    if (!isMissing && data === null && id !== 0) {
        isLoading = true;
    }

    const loadingClass = conditionalClass(isLoading, 'loading', undefined);
    const missingClass = conditionalClass(isMissing, 'missing', undefined);
    const bgImage =
        id !== 0 ? `url('data:image/jpeg;charset=utf-8;base64,${data}` : 'none';

    return (
        <div className={classes('texture-entry', textureEntryClass)}>
             {(dataAccess >= 3 && id!==0) ? (
                <label className={classes('swap_texture_button', swapClass)}>
                    <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        multiple={false}
                        onChange={onSwapTexture}
                    />
                </label>
            ) : null}
            <div
                className={classes(
                    'texture',
                    sideClass,
                    loadingClass,
                    missingClass
                )}
                style={{ backgroundImage: bgImage, cursor:(id===0?'default':undefined) }}
                onClick={handleClick}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <span className="texture-facing">{headerText}</span>
            <select
                className={classes('texture-selection', sideClass)}
                value={id}
                onChange={onTextureChanged}
            >
                {resources.map((r) => {
                    return (
                        <option key={r[0]} value={r[0]}>
                            {r[1]}
                        </option>
                    );
                })}
            </select>
            {dataAccess >= 2 && deleteEnabled && (
                <input
                    type="button"
                    className={classes('delete-page', deleteClass)}
                    onClick={(e) => {
                        e.preventDefault();
                        if (props.delete) props.delete();
                    }}
                ></input>
            )}
        </div>
    );
}
