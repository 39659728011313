import { resolve } from 'inversify-react';
import React from 'react';
import TokenList from '../../../models/dod/tokens/TokenList';
import TokenModel from '../../../models/dod/tokens/TokenModel';
import type { IAuthService } from '../../../services/IAuthService';
import type ITextureRegistry from '../../../services/ITextureRegistry';
import type ITokenService from '../../../services/ITokenService';
import type IUIService from '../../../services/IUIService';
import DefaultListOptionView from '../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../general/list-view/list-view.component';
import TokenCreateButton from '../token-create-button/token-create-button';
import TokenFormComponent from '../token-form/token-form.component';
import './token-directory.styles.scss';

interface TokenDirectoryListStateFragment {
    elements: TokenList | null;
    page: number;
    selected: number;
}

interface TokenDirectoryState {
    tokenList: TokenDirectoryListStateFragment;
    selectedToken: TokenModel | null;
    saveEnabled: boolean;
}

export default class TokenDirectory extends React.Component<
    {},
    TokenDirectoryState
> {
    @resolve('IAuthService')
    private readonly authService!: IAuthService;

    @resolve('ITokenService')
    private readonly tokenService!: ITokenService;

    @resolve('IUIService')
    private readonly uiService!: IUIService;

    @resolve('ITextureRegistry')
    private readonly textureRegistry!: ITextureRegistry;

    private readonly tokenList: TokenList;

    constructor(props: {}) {
        super(props);
        this.tokenList = new TokenList();
        this.state = {
            tokenList: {
                elements: null,
                page: 1,
                selected: -1,
            },
            selectedToken: null,
            saveEnabled: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    async getData(forceSetFirst: boolean = false) {
        this.uiService.showLoading();
        const response = await this.tokenService.getTokenList(
            this.state.tokenList.page
        );
        if (response === null) {
            this.uiService.hideLoading();
            this.uiService.showErrorNotification('Loading token list failed!');
            return;
        }
        this.uiService.hideLoading();
        
        if (!response.items) return;

        const setToFirst =
            response.items.length > 0 &&
            (this.state.tokenList.selected === -1 || forceSetFirst);

        if (setToFirst && response.items[0]) {
            const firstId = response.items[0].id;
            this.onSelectedItemChanged(firstId);
            this.setState({
                ...this.state,
                tokenList: {
                    ...this.state.tokenList,
                    selected: firstId,
                },
            });
        } else {
            this.setState({
                ...this.state,
                tokenList: {
                    ...this.state.tokenList,
                    elements: response,
                },
            });
        }
    }

    onCreateToken = async (tokenName: string, files: File[]) => {
        this.uiService.showLoading();
        const response = await this.tokenService.createToken(
            tokenName,
            files,
            (index, total, progress) => {
                const percentage =
                    (index / total + (1 / total) * progress) * 100;
                this.uiService.showProgressLoading(
                    percentage,
                    `Uploading ${files[index].name}`
                );
            }
        );
        this.uiService.hideLoading();

        if (response != null) {
            this.uiService.showErrorNotification(response.value);
            return;
        } else {
            this.uiService.showSuccessNotification(
                'Successfuly created a token!'
            );
        }

        this.getData(true);
    };

    onListPageChanged = (page: number) => {
        this.setState(
            {
                ...this.state,
                tokenList: {
                    ...this.state.tokenList,
                    page: page,
                },
            },
            () => this.getData()
        );
    };

    onSelectedItemChanged = async (id: number) => {
        this.uiService.showLoading();
        const token = await this.tokenService.getToken(id);
        this.uiService.hideLoading();
        if (token === null) {
            this.uiService.showErrorNotification('Failed to get token data!');
            return;
        }
        this.textureRegistry.put(token.resources);
        this.textureRegistry
            .loadTexturesFromCloud((fileName) => {
                return 'Streaming/Tokens/' + fileName;
            })
            .then((t) => {
                if (t) {
                    this.forceUpdate();
                }
            });
        this.setState({
            ...this.state,
            selectedToken: token,
            saveEnabled: false,
            tokenList: {
                ...this.state.tokenList,
                selected: id,
            },
        });
    };

    onRefresh = () => {
        this.onSelectedItemChanged(this.state.tokenList.selected);
    };

    onTokenUpdated = (model: TokenModel) => {
        this.setState({
            ...this.state,
            selectedToken: model,
            saveEnabled: true,
        });
    };

    onSaveToken = async () => {
        if (this.state.selectedToken === null) return;
        this.uiService.showLoading();
        const status = await this.tokenService.updateToken(
            this.state.selectedToken
        );
        this.uiService.hideLoading();
        if (status === null) {
            this.uiService.showSuccessNotification('Token saved successfuly');
            this.getData();
            this.onRefresh();
        } else {
            if (status.statusCode === 409) {
                this.onRefresh();
            }
            this.uiService.showErrorNotification(status.value);
        }
    };

    onDeleteToken = async () => {
        if (this.state.selectedToken === null) return;
        const confirm = await this.uiService.showConfirmationDialog(
            `Are you sure you want to delete token ${this.state.selectedToken.name}?`,
            'No',
            'Yes'
        );
        if (!confirm) return;
        this.uiService.showLoading();
        const success = await this.tokenService.deleteToken(
            this.state.selectedToken.id
        );
        this.uiService.hideLoading();
        if (success) {
            this.uiService.showSuccessNotification('Token deleted successfuly');
        } else {
            this.uiService.showErrorNotification('Failed to delete token');
        }
        this.getData(true);
    };

    onSwapTexture = async (side: string, file: File) => {
        if (this.state.selectedToken === null) return;

        this.uiService.showProgressLoading(0, 'Preparing!');

        const swapped = await this.tokenService.swapTextureToken(
            this.state.selectedToken.id,
            side,
            file,
            (progress) => {
                this.uiService.showProgressLoading(progress * 100, null);
            }
        );

        if (swapped != null) {
            this.uiService.showErrorNotification(swapped.value);
        } else {
            this.uiService.showSuccessNotification(
                'Texture swapped successfully'
            );
            this.onSelectedItemChanged(this.state.selectedToken.id);
        }

        this.uiService.hideLoading();
    };

    render(): React.ReactNode {
        return (
            <div className="container token-directory-container">
                <div style={{ display: 'inline' }}>
                    <div style={{ float: 'left' }}>
                        <h3 style={{ textAlign: 'center' }}>Tokens</h3>
                        {this.renderCreateButton()}
                        {this.renderList()}
                    </div>
                    {this.renderForm()}
                </div>
            </div>
        );
    }

    renderCreateButton = () => {
        const dataAccess = this.authService.getAccessLevel('data');
        if (dataAccess < 1) return null;
        return <TokenCreateButton createToken={this.onCreateToken} />;
    };

    renderList = () => {
        const { tokenList } = this.state;
        if (tokenList === null) return;
        if (tokenList.elements === null) {
            return;
        }
        this.tokenList.items = tokenList.elements.items;
        this.tokenList.max_results = tokenList.elements.max_results;

        return (
            <ListViewComponent
                elements={this.tokenList}
                page={tokenList.page}
                pageChanged={this.onListPageChanged}
                listElement={(e) => {
                    return (
                        <DefaultListOptionView
                            id={e.id}
                            key={e.id}
                            value={e.name}
                        />
                    );
                }}
                selectionChanged={this.onSelectedItemChanged}
                selectedValue={tokenList.selected}
                containerId="token-list"
                listClass="token_list"
                listId="tokens"
            />
        );
    };

    renderForm = () => {
        return (
            <TokenFormComponent
                token={this.state.selectedToken}
                refresh={this.onRefresh}
                update={this.onTokenUpdated}
                save={this.onSaveToken}
                delete={this.onDeleteToken}
                swapTexture={this.onSwapTexture}
                saveEnabled={this.state.saveEnabled}
            />
        );
    };
}
