import IList from "../../IList";
import TokenEntry from "./TokenEntry";

export default class TokenList implements IList<TokenEntry>{
    max_results!: number;
    items! : TokenEntry[];

    getItems(): TokenEntry[] {
        return this.items;
    }
}