import { inject, injectable } from 'inversify';
import Book from '../../models/dod/books/Book';
import BookList from '../../models/dod/books/BookList';
import { ErrorMessage } from '../../models/ErrorMessage';
import IDoDRESTClient from '../../rest-clients/IDoDRESTClient';
import IGoogleStorageRESTClient, {
    UploadProgress,
    UploadProgressMultiple,
} from '../../rest-clients/IGoogleStorageRESTClient';
import IBookService from '../IBookService';
import IFileNameValidatorService from '../IFileNameValidatorService';

@injectable()
export default class BookService implements IBookService {
    @inject('IDoDRESTClient')
    private readonly dodRestClient!: IDoDRESTClient;

    @inject('IFileNameValidatorService')
    private readonly fileNameValidatorService!: IFileNameValidatorService;

    @inject('IGoogleStorageRESTClient')
    private readonly storageRestClient!: IGoogleStorageRESTClient;

    private size: string[] = [];

    constructor() {
        this.size.push('small');
        this.size.push('large');
        this.size.push('large');
    }

    getBookPageTextureClass(size: number): string {
        return this.size[size];
    }
    async getBookList(page: number): Promise<BookList | null> {
        return await this.dodRestClient.getBookList(page);
    }
    async getBook(bookId: number): Promise<Book | null> {
        return await this.dodRestClient.getBook(bookId);
    }
    async createBook(
        bookName: string,
        files: File[],
        uploadProgressCallback: UploadProgressMultiple
    ): Promise<ErrorMessage> {
        if (files.length < 1) {
            return { value: 'Incorrect number of textures selected!' };
        }
        for (let i = 0; i < files.length; i++) {
            const { name } = files[i];
            if (this.fileNameValidatorService.validateName(name)) {
                return { value: `Invalid characters in the file name ${name}` };
            }
        }

        await this.dodRestClient.deleteTempFolder();

        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            const uploaded = await this.storageRestClient.uploadTempFile(
                file,
                (progress) => {
                    uploadProgressCallback(index, files.length, progress);
                }
            );
            if (!uploaded) {
                console.log('Failed to upload file');
            }
        }

        await this.dodRestClient.uploadBook(bookName);

        return null;
    }
    async deleteBook(bookId: number): Promise<boolean> {
        return await this.dodRestClient.deleteBook(bookId);
    }

    async updateBook(book: Book): Promise<ErrorMessage> {
        return await this.dodRestClient.updateBook(book);
    }

    async swapTextureBookCover(
        bookId: number,
        side: string,
        file: File,
        uploadProgress: UploadProgress
    ): Promise<ErrorMessage> {
        if (file === null) return { value: 'Wrong file' };

        const { name } = file;
        if (this.fileNameValidatorService.validateName(name)) {
            return { value: `Invalid characters in the file name ${name}` };
        }

        const destination = `Streaming/Books/${bookId}/${name}`;

        if (await this.storageRestClient.fileExists(destination)) {
            return { value: 'File already exists' };
        }

        const uploadStatus = await this.storageRestClient.uploadFile(
            destination,
            file,
            uploadProgress
        );

        if (!uploadStatus) {
            return { value: 'Failed to upload the texture' };
        }

        const dbResponse = await this.dodRestClient.swapBookTextureCover(
            bookId,
            side,
            name
        );

        if (!dbResponse) {
            return { value: 'Failed to swap the texture' };
        }

        return null;
    }

    async swapTextureBookPage(
        bookId: number,
        pageId: number,
        file: File,
        uploadProgress: UploadProgress
    ): Promise<ErrorMessage> {
        if (file === null) return { value: 'Wrong file' };

        const { name } = file;
        if (this.fileNameValidatorService.validateName(name)) {
            return { value: `Invalid characters in the file name ${name}` };
        }

        const destination = `Streaming/Books/${bookId}/${name}`;

        if (await this.storageRestClient.fileExists(destination)) {
            return { value: 'File already exists' };
        }

        const uploadStatus = await this.storageRestClient.uploadFile(
            destination,
            file,
            uploadProgress
        );

        if (!uploadStatus) {
            return { value: 'Failed to upload the texture' };
        }

        const dbResponse = await this.dodRestClient.swapBookTexturePage(
            bookId,
            pageId,
            name
        );

        if (!dbResponse) {
            return { value: 'Failed to swap the texture' };
        }

        return null;
    }
}
