import { useInjection } from 'inversify-react';
import React from 'react';
import { IAuthService } from '../../../services/IAuthService';

interface DeckToolbarProps {
    refreshDeck: () => void;
    saveDeck: () => void;
    deleteDeck: () => void;
    saveEnabled : boolean
}

export default function DeckToolbar(props : DeckToolbarProps) {
    var authService = useInjection<IAuthService>('IAuthService');

    const dataAccess = authService.getAccessLevel('data');

    const {saveEnabled} = props;

    const onRefreshDeck = (event : React.MouseEvent<HTMLButtonElement>)=>{
        event.preventDefault();
        props.refreshDeck();
    }

    const onSaveDeck = (event : React.MouseEvent<HTMLButtonElement>)=>{
        event.preventDefault();
        props.saveDeck();
    }

    const onDeleteDeck = (event: React.MouseEvent<HTMLButtonElement>)=>{
        event.preventDefault();
        props.deleteDeck();
    }

    return (
        <div className="toolbar">
            <button
                type="button"
                id="refresh_deck_button"
                className="refresh_button"
                onClick={onRefreshDeck}
            ></button>
            {dataAccess >= 2 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        type="button"
                        id="save_deck_button"
                        className="save_button"
                        onClick={onSaveDeck}
                        disabled={!saveEnabled}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : null}
            {dataAccess >= 3 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        type="button"
                        id="delete_deck_button"
                        className="delete_button"
                        onClick={onDeleteDeck}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : null}
        </div>
    );
}
