import 'reflect-metadata'
import React from 'react'
import './App.scss'
import { Container } from 'inversify'
import { Provider } from 'inversify-react'
import { Provider as ReduxProvider } from 'react-redux'
import { AuthRESTClient } from './rest-clients/Implementations/AuthRESTClient'
import { SessionStorageService } from './services/Implementations/SessionStorageService'
import { AuthService } from './services/Implementations/AuthService'
import PageWithHeader from './pages/page-with-header/PageWithHeader'
import DoDRESTClient from './rest-clients/Implementations/DoDRESTClient'
import store from './redux/store'
import DoDService from './services/Implementations/DoDService'
import LoadingOverlay from './components/loading-container/loading-container.component'
import UIService from './services/Implementations/UIService'
import LayoutService from './services/Implementations/LayoutService'
import TokenService from './services/Implementations/TokenService'
import TextureRegistry from './services/Implementations/TextureRegistry'
import GoogleStorageRESTClient from './rest-clients/Implementations/GoogleStorageRESTClient'
import FileNameValidatorService from './services/Implementations/FileNameValidatorService'
import DeckService from './services/Implementations/DeckService'
import WorkerRequestService from './services/Implementations/WorkerRequestService'
import ConfirmDialog from './components/confirm-dialog/confirm-dialog.component'
import BookService from './services/Implementations/BookService'
import ZoomService from './services/Implementations/ZoomService'
import ZoomImageOverlay from './components/zoom-image-overlay/zoom-image-overlay.component'
import FooterComponent from './components/footer/footer.component'
import ImageThumbRESTClient from './rest-clients/Implementations/ImageThumbRESTClient'
import GDMRESTClient from './rest-clients/Implementations/GDMRESTClient'
import BootStrapRecordSheets from './modules/record-sheets-module/bootstrapRecordSheet'

class App extends React.Component {
    private readonly container: Container = new Container()
    constructor(props: {}) {
        super(props)
        this.container.bind('IAuthRESTClient').to(AuthRESTClient).inSingletonScope()
        this.container.bind('ISessionStorageService').to(SessionStorageService).inSingletonScope()
        this.container.bind('IAuthService').to(AuthService).inSingletonScope()
        this.container.bind('IDoDRESTClient').to(DoDRESTClient).inSingletonScope()
        this.container.bind('IGDMRESTClient').to(GDMRESTClient).inSingletonScope()
        this.container.bind('IGoogleStorageRESTClient').to(GoogleStorageRESTClient).inSingletonScope()
        this.container.bind('IImageThumbRESTClient').to(ImageThumbRESTClient).inSingletonScope()
        this.container.bind('IDoDService').to(DoDService).inSingletonScope()
        this.container.bind('IUIService').to(UIService).inSingletonScope()
        this.container.bind('ILayoutService').to(LayoutService).inSingletonScope()
        this.container.bind('IDeckService').to(DeckService).inSingletonScope()
        this.container.bind('ITokenService').to(TokenService).inSingletonScope()
        this.container.bind('IBookService').to(BookService).inSingletonScope()
        this.container.bind('IZoomService').to(ZoomService).inSingletonScope()
        this.container.bind('IWorkerRequestService').to(WorkerRequestService);
        this.container.bind('IFileNameValidatorService').to(FileNameValidatorService ).inSingletonScope()
        this.container.bind('ITextureRegistry').to(TextureRegistry).inSingletonScope()
        BootStrapRecordSheets(this.container);
    }

    render() {
        return (
            <div id="background-wrap">
                <ReduxProvider store={store}>
                    <Provider container={this.container}>
                        <React.Fragment>
                            <PageWithHeader />
                            <LoadingOverlay/>
                            <ConfirmDialog/>
                            <ZoomImageOverlay/>
                        </React.Fragment>
                    </Provider>
                </ReduxProvider>
                
                <FooterComponent/>
            </div>
        )
    }
}

export default App
