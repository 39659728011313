import React from 'react';
import Header from '../../components/header/header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from '../homepage/HomePage';
import SettlementPage from '../settlement-page/SettlementPage';
import NoAccessPage from '../no-access/NoAccessPage';
import ProtectedPage from '../../components/protected-page/protected-page';
import { RootState } from '../../redux/store';
import { connect } from 'react-redux';
import TokenPage from '../token-page/TokenPage';
import { DeckPage } from '../deckpage/DeckPage';
import BookPage from '../bookPage/BookPage';

interface PageWithHeaderProps {
    isAuthorized: boolean;
}
class PageWithHeader extends React.Component<PageWithHeaderProps, {}> {
    componentDidUpdate() {
        console.log(this.props.isAuthorized);
    }

    render() {
        return (
            <React.Fragment>
                <BrowserRouter basename={process.env.REACT_APP_ROUTE_BASE_NAME}>

                <Header />

                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route
                            path="/records"
                            element={
                                <ProtectedPage
                                    access="data"
                                    accessLevel={-1}
                                    children={<SettlementPage />}
                                    redirectTo="/no-access"
                                />
                            }
                        />
                        <Route
                            path="/tokens"
                            element={
                                <ProtectedPage
                                    access="data"
                                    accessLevel={1}
                                    children={<TokenPage />}
                                    redirectTo="/no-access"
                                />
                            }
                        />
                        <Route
                            path="/decks"
                            element={
                                <ProtectedPage
                                    access="data"
                                    accessLevel={1}
                                    children={<DeckPage />}
                                    redirectTo="/no-access"
                                />
                            }
                        />
                        <Route
                            path="/books"
                            element={
                                <ProtectedPage
                                    access="data"
                                    accessLevel={1}
                                    children={<BookPage />}
                                    redirectTo="/no-access"
                                />
                            }
                        />
                        <Route path="/no-access" element={<NoAccessPage />} />
                    </Routes>

                </BrowserRouter>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.auth.jwtToken !== null,
});

export default connect(mapStateToProps)(PageWithHeader);
