export const mockSurvivorList = {
    max_results: 2,
    survivors: [
        {
            id: 4658,
            survivor_type: 2,
            survivor_name: 'New Survivor',
            survivor_sex: 0,
            notes: '',
            dead: 0,
        },
        {
            id: 4649,
            survivor_type: 2,
            survivor_name: 'New Survivor',
            survivor_sex: 0,
            notes: '',
            dead: 0,
        },
    ],
};

export const mockCoreSurvivorSheet = {
    survival_count: 0,
    survival_check: 0,
    encourage_check: 0,
    surge_check: 0,
    dash_check: 0,
    endure_check: 0,
    hunt_xp: 0,
    weapon_proficiency: null,
    weapon_xp: 0,
    accuracy_count: '0',
    strength_count: '0',
    evasion_count: '0',
    luck_count: '0',
    speed_count: '0',
    insanity_count: 0,
    brain_check: 0,
    courage_xp: 0,
    understanding_xp: 0,
    stalwart_check: 0,
    prepared_check: 0,
    match_check: 0,
    analyze_check: 0,
    explore_check: 0,
    tinker_check: 0,
    head_count: 0,
    arms_count: 0,
    body_count: 0,
    waist_count: 0,
    legs_count: 0,
    head_toggle: 0,
    arms_toggle_l: 0,
    arms_toggle_h: 0,
    body_toggle_l: 0,
    body_toggle_h: 0,
    waist_toggle_l: 0,
    waist_toggle_h: 0,
    legs_toggle_l: 0,
    legs_toggle_h: 0,
    arts_check: 0,
    hunt_check: 0,
    reroll_check: 0,
    fighting_arts: [],
    disorders: [],
    abilities: [],
    lifetime_entry: null,
    movement: 5,
    alternative_survivors: null,
    id: 4605,
    parent_id: 4605,
    settlement_id: 135,
    survivor_type: 0,
    lantern_year: 1,
    survivor_name: 'New Survivor',
    survivor_sex: 0,
    notes: null,
    dead: 0,
    last_updated: '2023-01-30 15:51:41.000',
    gears: [null, null, null, null, null, null, null, null, null],
};

export const mockArkSurvivalSheet = {
    survival_count: 0,
    survival_check: 0,
    encourage_check: 0,
    surge_check: 0,
    dash_check: 0,
    endure_check: 0,
    hunt_xp: 0,
    weapon_proficiency: null,
    weapon_xp: 0,
    accuracy_count: '0',
    strength_count: '0',
    evasion_count: '0',
    luck_count: '0',
    speed_count: '0',
    insanity_count: 0,
    brain_check: 0,
    courage_xp: 0,
    understanding_xp: 0,
    stalwart_check: 0,
    prepared_check: 0,
    match_check: 0,
    analyze_check: 0,
    explore_check: 0,
    tinker_check: 0,
    head_count: 0,
    arms_count: 0,
    body_count: 0,
    waist_count: 0,
    legs_count: 0,
    head_toggle: 0,
    arms_toggle_l: 0,
    arms_toggle_h: 0,
    body_toggle_l: 0,
    body_toggle_h: 0,
    waist_toggle_l: 0,
    waist_toggle_h: 0,
    legs_toggle_l: 0,
    legs_toggle_h: 0,
    arts_check: 0,
    hunt_check: 0,
    reroll_check: 0,
    fighting_arts: [],
    disorders: [],
    abilities: [],
    lifetime_entry: null,
    movement: 5,
    alternative_survivors: null,
    id: 4605,
    parent_id: 4605,
    settlement_id: 135,
    survivor_type: 1,
    lantern_year: 1,
    survivor_name: 'New Survivor',
    survivor_sex: 0,
    notes: null,
    dead: 0,
    last_updated: '2023-01-30 15:51:41.000',
    gears: [null, null, null, null, null, null, null, null, null],
};
