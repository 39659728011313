import { inject, injectable } from 'inversify';
import TokenList from '../../models/dod/tokens/TokenList';
import TokenModel from '../../models/dod/tokens/TokenModel';
import { ErrorMessage } from '../../models/ErrorMessage';
import IDoDRESTClient from '../../rest-clients/IDoDRESTClient';
import IGoogleStorageRESTClient, {
    UploadProgress,
    UploadProgressMultiple,
} from '../../rest-clients/IGoogleStorageRESTClient';
import IFileNameValidatorService from '../IFileNameValidatorService';
import ITokenService from '../ITokenService';

@injectable()
export default class TokenService implements ITokenService {
    @inject('IFileNameValidatorService')
    private fileNameValidatorService!: IFileNameValidatorService;

    @inject('IGoogleStorageRESTClient')
    private googleCloudStorage!: IGoogleStorageRESTClient;

    private textureClasses: string[];

    constructor() {
        this.textureClasses = [
            'circle s1x1',
            'rect s1x1',
            'rect s2x2',
            'rect s2x3',
            'rect s3x3',
            'rect s1x2',
            'rect s1x4',
            'blood s1x2',
            'cognition',
            'settlement',
        ];
    }

    @inject('IDoDRESTClient')
    private readonly dodRestClient!: IDoDRESTClient;

    @inject('IGoogleStorageRESTClient')
    private readonly storageRestClient!: IGoogleStorageRESTClient;

    async getTokenList(page: number): Promise<TokenList | null> {
        return await this.dodRestClient.getTokenList(page);
    }

    async getToken(tokenId: number): Promise<TokenModel | null> {
        return await this.dodRestClient.getTokenById(tokenId);
    }

    getTextureClass(size: number): string {
        return this.textureClasses[size];
    }

    async deleteToken(tokenId: number): Promise<boolean> {
        return await this.dodRestClient.deleteToken(tokenId);
    }
    async updateToken(model: TokenModel): Promise<ErrorMessage> {
        return await this.dodRestClient.updateToken(model);
    }

    async createToken(
        tokenName: string,
        files: File[],
        uploadProgressCallback: UploadProgressMultiple
    ): Promise<ErrorMessage> {
        if (files.length < 1) {
            return { value: 'Incorret number of textures selected!' };
        }
        for (let i = 0; i < files.length; i++) {
            const { name } = files[i];
            if (this.fileNameValidatorService.validateName(name)) {
                return { value: `Invalid characters in the file name ${name}` };
            }
        }

        await this.dodRestClient.deleteTempFolder();

        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            const uploaded = await this.storageRestClient.uploadTempFile(
                file,
                (progress) => {
                    uploadProgressCallback(index, files.length, progress);
                }
            );
            if (!uploaded) {
                console.log('Failed to upload file');
            }
        }

        await this.dodRestClient.uploadToken(tokenName);

        return null;
    }

    async swapTextureToken(
        tokenId: number,
        side: string,
        file: File,
        uploadProgress: UploadProgress
    ): Promise<ErrorMessage> {
        if (file === null) return { value: 'Wrong file' };

        const { name } = file;
        if (this.fileNameValidatorService.validateName(name)) {
            return { value: `Invalid characters in the file name ${name}` };
        }

        const destination = `Streaming/Tokens/${tokenId}/${name}`;

        if (await this.storageRestClient.fileExists(destination)) {
            return { value: 'File already exists' };
        }

        const uploadStatus = await this.storageRestClient.uploadFile(
            destination,
            file,
            uploadProgress
        );

        if (!uploadStatus) {
            return { value: 'Failed to upload the texture' };
        }

        const dbResponse = await this.dodRestClient.swapTokenTexture(
            tokenId,
            side,
            name
        );

        if (!dbResponse) {
            return { value: 'Failed to swap the texture' };
        }

        return null;
    }
}
