import { Overlay } from '../overlay/overlay.component';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import './zoom-overlay.styles.scss';
import classes from '../../utils/classes';
import { resolve } from 'inversify-react';
import ITextureRegistry from '../../services/ITextureRegistry';
import IZoomService from '../../services/IZoomService';
import conditionalClass from '../../utils/conditional-class';
import React from 'react';

interface ZoomImageOverlayProps {
    zoomed: boolean;
    path: string | null;
    sizeClass: string;
}

interface ZoomImageOverlayState {
    data: string | null;
}
class ZoomImageOverlay extends React.Component<
    ZoomImageOverlayProps,
    ZoomImageOverlayState
> {
    @resolve('ITextureRegistry')
    private readonly textureRegistry!: ITextureRegistry;

    @resolve('IZoomService')
    private readonly zoomService!: IZoomService;

    constructor(props: ZoomImageOverlayProps) {
        super(props);
        this.state = {
            data: null,
        };
    }

    zoomOut = () => {
        this.setState(
            {
                data: null,
            },
            () => {
                this.zoomService.zoomOut();
            }
        );
    };

    getBgImage = (data: string | null) => {
        if (data === null) return 'none';
        return `url('data:image/jpeg;charset=utf-8;base64,${data}`;
    };

    componentDidUpdate(prevProps: ZoomImageOverlayProps) {
        if (prevProps.path !== this.props.path) {
            if (this.state.data !== null) {
                this.setState(
                    {
                        data: null,
                    },
                    () => {
                        this.getData();
                    }
                );
            } else this.getData();
        }
    }

    getData() {
        const { path } = this.props;
        if (path !== null) {
            this.textureRegistry.getTextureDataCallbackSingleBuffer(
                path,
                (data) => {
                    this.setState({
                        data: data,
                    });
                }
            );
        }
    }

    render() {
        const { zoomed, sizeClass } = this.props;

        const { data } = this.state;

        return (
            <Overlay open={zoomed}>
                <div id="wrapper" onClick={this.zoomOut}>
                    <div
                        className={classes(
                            'zoom-texture',
                            sizeClass,
                            conditionalClass(
                                data === '' || data === null,
                                'loading',
                                undefined
                            )
                        )}
                        style={{
                            backgroundImage:
                                data === '' || data === null
                                    ? 'none'
                                    : this.getBgImage(data),
                        }}
                        onClick={this.zoomOut}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </Overlay>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    zoomed: state.zoom.zoomed,
    path: state.zoom.path,
    sizeClass: state.zoom.sizeClass,
});

export default connect(mapStateToProps)(ZoomImageOverlay);
