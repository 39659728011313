import DeckEntry from "./DeckEntry";
import IList from "../../IList";

export default class DeckList implements IList<DeckEntry>{
    max_results!: number;
    items!: DeckEntry[];

    getItems(): DeckEntry[] {
        return this.items;
    }
}