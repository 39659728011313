import SettlementList from "../modules/record-sheets-module/models/settlements/SettlementList";

const settlementListMockData = {
    max_results: 3,
    settlements: [
        {
            id: 137,
            settlement_type: 0,
            settlement_name: 'Test Settlement 2',
        },
        {
            id: 135,
            settlement_type: 0,
            settlement_name: 'Test Settlement',
        },

        {
            id: 201,
            settlement_type: 1,
            settlement_name: 'Ark test',
        },
        {
            id: 291,
            settlement_type: 2,
            settlement_name: 'People of the star',
        },
    ],
};

const mockSettlementList = new SettlementList();
mockSettlementList.max_results = settlementListMockData.max_results;
mockSettlementList.settlements = settlementListMockData.settlements;
export { mockSettlementList };

// settlement sheet mock data
// core
export const mockCoreSettlementSheet = {
    survival_limit: 1,
    death_count: 0,
    lantern_year: 0,
    quarry_check_0: 0,
    quarry_check_1: 0,
    nemesis_0: ' ',
    nemesis_1: ' ',
    research: 0,
    population: 0,
    lost_settlements: 0,
    timeline_events: [
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
    ],
    milestones: [0, 0, 0, 0, 0],
    innovations: [
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
    ],
    principle_checks: [0, 0, 0, 0, 0, 0, 0, 0],
    principles: [' ', ' '],
    settlement_checks: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    settlements: [' ', ' ', ' ', ' ', ' ', ' '],
    quarries: [' ', ' ', ' ', ' ', ' '],
    resources: [
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
    ],
    gears: [
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
    ],
    nemesis_checks: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    volumes: [' ', ' ', ' ', ' '],
    notes: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
    id: 135,
    settlement_type: 0,
    settlement_name: 'Test Settlement',
    description: null,
    expansion_ids: ' ',
    last_updated: '2023-01-16 11:07:39.000',
    owner: 'garmush@gmail.com',
};
// ark
export const mockArkSettlementSheet = {
    id: 201,
    settlement_type: 1,
    settlement_name: 'ark test',
    description: '',
    expansion_ids: ' ',
    last_updated: '2023-02-06 13:24:16.000',
    owner: null,
};

export const mockPotStarSettlementSheet = {
    survival_limit: 1,
    death_count: 26,
    lantern_year: 37,
    timeline_events: [
        '',
        ' xcvd',
        ' sdfasdfdsa',
        ' ',
        ' sadfasdf',
        ' sadfasdfdfd',
        ' sadfasd',
        ' sadfasdfdfsadf',
        ' sadfasdf',
        ' sadfasd',
        ' asdfsadfsadf',
        ' sadf',
        ' asdf',
        ' sadf',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' sadfas',
        ' sadf',
        ' asdf',
        ' asdf',
        ' sadfsadf',
        ' sadfasd',
        ' sdfasdf',
        ' sadfsadf',
        ' sadf',
        ' asdf',
        ' sadf',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
    ],
    mile_checks: [0, 0, 1, 1],
    milestones: ['asdf', 'asdf', 'sadfa', 'asdf', ''],
    innovations: [
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' bmnbnm',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' vbvn',
        ' sadfasdf',
        ' ',
        ' ',
        ' ',
    ],
    principle_checks: [0, 0, 0, 1, 0, 0, 0, 1],
    principles: [' sadf', ' asdf'],
    settlement_checks: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    settlements: [' ', ' asdfsadf', ' ', ' ', ' asdf', ' '],
    quarry_checks: [0, 1],
    quarries: [' asdf', ' asdf', ' sadf', ' sadf', ' sadf'],
    nemesis_checks: [0, 0, 0, 0, 0, 0, 1, 1, 1],
    nemesis: ['', ''],
    resources: [
        ' ',
        ' sdfasdf',
        ' ',
        ' ',
        ' asdf',
        ' ',
        ' asdfafds',
        ' ',
        ' ',
        ' aasdf',
        ' asdfasdf',
        ' ',
        ' ',
        ' ',
        ' asdfadsf',
        ' ',
        ' ',
        ' asdfasfd',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' asdf',
        ' ',
        ' ',
        ' ',
        ' sadfasdf',
        ' ',
        ' ',
        ' asdfasdf',
        ' ',
        ' asdfasd',
        ' ',
        ' ',
        ' asdfasdf',
        ' ',
        ' ',
        ' ',
    ],
    defeated: [
        '',
        '',
        '',
        '',
        '',
        'asdf',
        '',
        '',
        '',
        '',
        '',
        '',
        'asdf',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'asdf',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'sadf',
    ],
    population: 0,
    lost_settlements: 11,
    id: 291,
    settlement_type: 2,
    settlement_name: 'Test People of Star',
    description: '',
    expansion_ids: ' ',
    last_updated: '2023-04-25 17:11:49.000',
    owner: null,
};

export const mockPotSunSettlementSheet = {
    survival_limit: 1,
    death_count: 0,
    lantern_year: 0,
    timeline_events: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ],
    mile_checks: [0, 0, 0, 0, 0],
    milestones: ['', '', '', ''],
    innovations: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ],
    principle_checks: [0, 0, 0, 0, 0],
    principles: ['', ''],
    settlement_checks: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    settlements: ['', '', '', '', ''],
    quarry_checks: [0, 0],
    quarries: ['', '', '', '', ''],
    nemesis_checks: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    nemesis: ['', '', '', ''],
    resources: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ],
    gears: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ],
    research: 0,
    volumes: ['', '', '', ''],
    notes: ['', '', '', '', '', '', '', '', '', '', ''],
    population: 0,
    lost_settlements: 0,
    id: 299,
    settlement_type: 3,
    settlement_name: 'Test People of Sun',
    description: null,
    expansion_ids: ' ',
    last_updated: '2023-04-27 17:02:03.000',
    owner: null,
};
