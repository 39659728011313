import { useInjection } from "inversify-react";
import { useEffect } from "react";
import { useNavigate } from "react-router"
import { IAuthService } from "../../services/IAuthService";

function HomePage(){

    const authService = useInjection<IAuthService>('IAuthService');

    const navigate = useNavigate();

    useEffect(()=>{
        if(authService.isAuthenticated()){
            navigate('/records');
        }
    });
    
    return (
        <div className="container"></div>
    )
}

export default HomePage
