import { resolve } from 'inversify-react';
import React from 'react';
import Book from '../../../models/dod/books/Book';
import BookList from '../../../models/dod/books/BookList';
import type IBookService from '../../../services/IBookService';
import type ITextureRegistry from '../../../services/ITextureRegistry';
import type IUIService from '../../../services/IUIService';
import DefaultListOptionView from '../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../general/list-view/list-view.component';
import BookCreateButton from '../book-create-button/book-create-button.component';
import BookFormContainer from '../book-form-container/book-form-container.component';
import BookToolbar from '../book-toolbar/book-toolbar.component';
import './book-directory.styles.scss';

interface BookDirectoryListStateFragment {
    elements: BookList | null;
    page: number;
    selected: number;
}

interface BookDirectoryState {
    bookList: BookDirectoryListStateFragment;
    selectedBook: Book | null;
    saveEnabled: boolean;
    formPageNumber : number
}

export default class BookDirectory extends React.Component<
    {},
    BookDirectoryState
> {
    @resolve('IUIService')
    private readonly uiService!: IUIService;

    @resolve('IBookService')
    private readonly bookService!: IBookService;

    @resolve('ITextureRegistry')
    private readonly textureRegistry!: ITextureRegistry;

    constructor(props: {}) {
        super(props);
        this.state = {
            bookList: {
                page: 1,
                elements: null,
                selected: -1,
            },
            selectedBook: null,
            saveEnabled: true,
            formPageNumber : 0
        };
    }

    componentDidMount() {
        this.getData();
    }

    async getData(forceSetFirst: boolean = false, reload: boolean = false) {
        this.uiService.showLoading();
        const response = await this.bookService.getBookList(
            this.state.bookList.page
        );
        if (response === null) {
            this.uiService.hideLoading();
            this.uiService.showErrorNotification('Loading deck list failed!');
            return;
        }
        this.uiService.hideLoading();

        if (!response.items) return;

        const setToFirst =
            response.items.length > 0 &&
            (this.state.bookList.selected === -1 || forceSetFirst);

        if (setToFirst && response.items[0]) {
            const firstId = response.items[0].id;
            this.onListSelectionChanged(firstId);
            this.setState({
                ...this.state,
                bookList: {
                    ...this.state.bookList,
                    selected: firstId,
                },
            });
        } else {
            this.setState({
                ...this.state,
                bookList: {
                    ...this.state.bookList,
                    elements: response,
                },
            });
        }
    }

    onListSelectionChanged = async (selectedId: number) => {
        this.uiService.showLoading();
        this.bookService
            .getBook(selectedId)
            .then((response) => {
                if (response === null) {
                    this.uiService.showErrorNotification(
                        'Failed to get book data'
                    );
                    return;
                }
                this.textureRegistry.put(response.resources);
                this.textureRegistry
                    .loadTexturesFromCloudThumbnails((fileName) => {
                        return 'Streaming/Books/' + fileName;
                    })
                    .then((t) => {
                        if (t) {
                            this.forceUpdate();
                        }
                    });

                this.setState({
                    ...this.state,
                    selectedBook: response,
                    bookList: {
                        ...this.state.bookList,
                        selected: selectedId,
                    },
                    saveEnabled: false,
                    formPageNumber : 0
                });
                this.uiService.hideLoading();
            })
            .catch(() => {
                this.uiService.hideLoading();
            });

        this.setState({
            ...this.state,
            bookList: {
                ...this.state.bookList,
                selected: selectedId,
            },
        });
    };

    onListPageChanged = (page: number) => {
        this.setState(
            {
                ...this.state,
                bookList: {
                    ...this.state.bookList,
                    page: page,
                },
            },
            () => {
                this.getData();
            }
        );
    };

    onCreateBook = async (bookName: string, files: File[]) => {
        this.uiService.showLoading();
        const response = await this.bookService.createBook(
            bookName,
            files,
            (index, total, progress) => {
                const percentage =
                    (index / total + (1 / total) * progress) * 100;
                this.uiService.showProgressLoading(
                    percentage,
                    `Uploading ${files[index].name}`
                );
            }
        );
        this.uiService.hideLoading();

        if (response != null) {
            this.uiService.showErrorNotification(response.value);
            return;
        } else {
            this.uiService.showSuccessNotification(
                'Successfuly created a book!'
            );
        }

        this.getData(true);
    };

    onRefresh = () => {
        this.onListSelectionChanged(this.state.bookList.selected);
    };

    onSave = async () => {
        if (this.state.selectedBook === null) return;
        this.uiService.showLoading();
        const status = await this.bookService.updateBook(
            this.state.selectedBook
        );
        this.uiService.hideLoading();
        if (status === null) {
            this.uiService.showSuccessNotification('Book saved successfuly');
            this.getData();
            this.onRefresh();
        } else {
            if (status.statusCode === 409) {
                this.onRefresh();
            }
            this.uiService.showErrorNotification(status.value);
        }
    };

    onDelete = async () => {
        if (this.state.selectedBook === null) return;
        const confirm = await this.uiService.showConfirmationDialog(
            `Are you sure you want to delete book ${this.state.selectedBook.name}?`,
            'No',
            'Yes'
        );
        if (!confirm) return;
        this.uiService.showLoading();
        const success = await this.bookService.deleteBook(
            this.state.selectedBook.id
        );
        this.uiService.hideLoading();
        if (success) {
            this.uiService.showSuccessNotification('Book deleted successfuly');
        } else {
            this.uiService.showErrorNotification('Failed to delete book');
        }
        this.getData(true);
    };

    onSwapBookCover = async (side: string, file: File) => {
        if (this.state.selectedBook === null) return;

        this.uiService.showProgressLoading(0, 'Preparing!');

        const swapped = await this.bookService.swapTextureBookCover(
            this.state.selectedBook.id,
            side,
            file,
            (progress) => {
                this.uiService.showProgressLoading(progress * 100, null);
            }
        );

        if (swapped != null) {
            this.uiService.showErrorNotification(swapped.value);
        } else {
            this.uiService.showSuccessNotification(
                'Texture swapped successfully'
            );
            this.onListSelectionChanged(this.state.selectedBook.id);
        }

        this.uiService.hideLoading();
    };

    onSwapBookPage = async (pageId: number, file: File) => {
        if (this.state.selectedBook === null) return;

        this.uiService.showProgressLoading(0, 'Preparing!');

        const swapped = await this.bookService.swapTextureBookPage(
            this.state.selectedBook.id,
            pageId,
            file,
            (progress) => {
                this.uiService.showProgressLoading(progress * 100, null);
            }
        );

        if (swapped != null) {
            this.uiService.showErrorNotification(swapped.value);
        } else {
            this.uiService.showSuccessNotification(
                'Texture swapped successfully'
            );
            this.onListSelectionChanged(this.state.selectedBook.id);
        }

        this.uiService.hideLoading();
    };

    onUpdate = (book: Book) => {
        this.setState({
            ...this.state,
            selectedBook: book,
            saveEnabled: true,
        });
    };

    setFormPageNumber = (pageNumber : number)=>{
        this.setState({
            ...this.state,
            formPageNumber : pageNumber
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="container book-directory-container">
                    <div style={{ display: 'inline' }}>
                        <div style={{ float: 'left' }}>
                            <h3 style={{ textAlign: 'center' }}>Books</h3>
                            {this.renderCreateButton()}
                            {this.renderList()}
                        </div>
                        {this.renderBookForm()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderBookForm(): React.ReactNode {
        if (this.state.selectedBook === null) return;
        return (
            <div style={{ textAlign: 'center', minWidth: '1140px' }}>
                <h3>Book Info</h3>
                <BookToolbar
                    refresh={this.onRefresh}
                    save={this.onSave}
                    delete={this.onDelete}
                    saveEnabled={this.state.saveEnabled}
                />
                <BookFormContainer
                    book={this.state.selectedBook}
                    update={this.onUpdate}
                    swapBookCover={this.onSwapBookCover}
                    swapPage={this.onSwapBookPage}
                    formPageNumber = {this.state.formPageNumber}
                    setFormPageNumber = {this.setFormPageNumber}
                />
            </div>
        );
    }

    renderList(): React.ReactNode {
        const { elements, page, selected } = this.state.bookList;
        return (
            <ListViewComponent
                elements={elements}
                page={page}
                selectedValue={selected}
                pageChanged={this.onListPageChanged}
                selectionChanged={this.onListSelectionChanged}
                listElement={(e) => {
                    return (
                        <DefaultListOptionView
                            value={e.name}
                            key={e.id}
                            id={e.id}
                        />
                    );
                }}
                containerId='book-list'
                listClass='book_list'
                listId='books'
            />
        );
    }

    renderCreateButton() {
        return <BookCreateButton createBook={this.onCreateBook} />;
    }
}
