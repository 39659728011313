import React, { useState } from 'react';
import './login-ribbon.styles.scss';
import { useInjection } from 'inversify-react';
import { IAuthService } from '../../services/IAuthService';
import { useNavigate } from 'react-router';
import IUIService from '../../services/IUIService';


function LoginRibbon() {
    const authService = useInjection<IAuthService>('IAuthService');
    const uiService = useInjection<IUIService>('IUIService');

    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const response = await authService.login(email, password);
        if(response){
            navigate('/records');
        }
        else{
            uiService.showErrorNotification('Failed to login. Wrong credentials!');
        }
    };

    const update = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { value, name } = event.target;
        
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    }

    return (
        <form
            style={{ float: 'right', marginRight: '10px' }}
            onSubmit={handleSubmit}
        >
            <input
                name="email"
                placeholder="email"
                id="email"
                className="b-blue"
                value={email}
                onChange={update}
            />
            <input
                name="password"
                placeholder="password"
                id="password"
                type="password"
                className="b-blue"
                value={password}
                onChange={update}
            />
            <input type="submit" value="Log In" className="b-blue" />
        </form>
    );
}

export default LoginRibbon;
