import { useInjection } from 'inversify-react';
import React from 'react';
import { IAuthService } from '../../../services/IAuthService';

interface BookToolbarProps {
    refresh: () => void;
    save: () => void;
    delete: () => void;
    saveEnabled : boolean;
}

export default function BookToolbar(props: BookToolbarProps) {
    const { refresh, save } = props;

    const authService = useInjection<IAuthService>('IAuthService');

    const dataAccess = authService.getAccessLevel('data');

    return (
        <div className="toolbar">
            <button
                type="button"
                id="refresh_book_button"
                className="refresh_button"
                onClick={(e) => {
                    e.preventDefault();
                    refresh();
                }}
            >
                {' '}
            </button>
            {dataAccess >= 2 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        type="button"
                        id="save_book_button"
                        className="save_button"
                        onClick={(e) => {
                            e.preventDefault();
                            save();
                        }}
                        disabled={!props.saveEnabled}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : (
                ''
            )}

            {dataAccess >= 3 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        type="button"
                        id="delete_book_button"
                        className="delete_button"
                        onClick={(e) => {
                            e.preventDefault();
                            props.delete();
                        }}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : (
                ''
            )}
        </div>
    );
}
