import { useInjection } from 'inversify-react'
import NoAccessPage from '../../pages/no-access/NoAccessPage';
import { IAuthService } from '../../services/IAuthService';

function ProtectedPage({
    children,
    redirectTo,
    accessLevel,
    access
}: {
    children: any
    redirectTo: string
    accessLevel: number,
    access : string
}): any {

    const authService = useInjection<IAuthService>("IAuthService");
    return authService.getAccessLevel(access)>=accessLevel ? children : <NoAccessPage/>
}

export default ProtectedPage
