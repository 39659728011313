import React from 'react'
import LoginRibbon from '../login-ribbon/login-ribbon.component'
import { connect } from 'react-redux'
import { RootState } from '../../redux/store'
import { resolve } from 'inversify-react'
import type ILayoutService from '../../services/ILayoutService'
import type { IAuthService } from '../../services/IAuthService'
import HeaderButton from './header-button/header-button.component'
import MenuAction from '../../models/MenuAction'
import classes from '../../utils/classes'
import { Link } from 'react-router-dom'

interface HeaderProps {
    isAuthorized: boolean,
    dataAccessLevels : number[]
}

class Header extends React.Component<HeaderProps, {}> {
    @resolve('ILayoutService')
    private layoutService!: ILayoutService

    @resolve('IAuthService')
    private authService!: IAuthService

    constructor(props: HeaderProps) {
        super(props)
        this.state = {
            menuItems: [],
            menuActions: [],
        }
    }

    onLogoutClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()
        this.authService.logout()
    }

    render() {
        return (
            <div>
                <form id="logoutForm" method="post" style={{ display: 'none' }}>
                    <input type="hidden" name="logout" value="true" />
                </form>
                <div className="b-blue-before"></div>
                <div className="b-blue">
                    <React.Fragment>
                        {this.renderHomeButton()}
                        {!this.props.isAuthorized ? (
                            <LoginRibbon />
                        ) : (
                            this.renderMenu()
                        )}
                    </React.Fragment>
                </div>
            </div>
        )
    }
    renderMenu() {
        return (
            <React.Fragment>
                {this.renderMenuItems()}
                {this.renderDropDownMenu()}
            </React.Fragment>
        )
    }

    renderMenuItems() {
        const accessLevel = this.authService.getAccessLevel('data')
        const menuItems = this.layoutService.getMenuItems(accessLevel)
        return (
            <div className="ribbon-menu">
                {menuItems.map((menuItem) => {
                    return (
                        <HeaderButton
                            key={menuItem.id}
                            name={menuItem.name}
                            target_path={menuItem.target_path}
                            iconId={menuItem.icon_id}
                        />
                    )
                })}
            </div>
        )
    }

    renderDropDownMenu() {
        const menuActions = this.layoutService.getActions()
        return (
            <div className="navbar">
                <div className="dropdown">
                    <button className="dropbtn"></button>
                    <div className="dropdown-container">
                        <div className="dropdown-content">
                            <table>
                                <tbody>
                                    {menuActions.map(
                                        (menuAction) => {
                                            return this.renderMenuAction(
                                                menuAction
                                            )
                                        }
                                    )}
                                    {menuActions.length>0 && <tr>
                                        <td style={{ overflowX: 'hidden' }}>
                                            <hr
                                                style={{
                                                    display: 'block',
                                                    margin: '10px -28px 0px 10px',
                                                }}
                                            />
                                        </td>
                                    </tr>}
                                    {this.renderLogoutButton()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private renderLogoutButton() {
        return (
            <tr>
                <td style={{ overflow: 'hidden' }}>
                    <a href="_self" onClick={this.onLogoutClick}>
                        Log out
                    </a>
                </td>
                <td>
                    <div className="icon logout"></div>
                </td>
            </tr>
        )
    }

    private renderMenuAction(menuAction: MenuAction): JSX.Element {
        return (
            <tr key={menuAction.id}>
                <td style={{ overflow: 'hidden' }}>
                    <Link to={menuAction.target_path}>
                        {menuAction.action_name}
                    </Link>
                </td>
                <td>
                    <div className={classes('icon', menuAction.icon_id)}></div>
                </td>
            </tr>
        )
    }

    renderHomeButton() {
        return (
            <a className="home-button" href="/" id="home" target="_self">
                {' '}
            </a>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.auth.jwtToken !== null,
    dataAccessLevels : state.auth.dataAccess
})

export default connect(mapStateToProps)(Header)
