import { rest } from 'msw';
import {
    mockArkSettlementSheet,
    mockCoreSettlementSheet,
    mockPotStarSettlementSheet,
    mockPotSunSettlementSheet,
    mockSettlementList,
} from './settlement-mocks';
import {
    mockArkSurvivalSheet,
    mockCoreSurvivorSheet,
    mockSurvivorList,
} from './survivor-mock';

const BASE_URL = 'https://wod-dod-uux56memxa-uc.a.run.app/api/v1';

export const handlers = [
    rest.get(`${BASE_URL}/settlement`, (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockSettlementList));
    }),
    rest.get(`${BASE_URL}/settlement/:id`, (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockCoreSettlementSheet));
    }),
    rest.get(`${BASE_URL}/settlement/:sheetId/survivor`, (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockSurvivorList));
    }),
    rest.get(`${BASE_URL}/survivor/:survivorId`, (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockCoreSurvivorSheet));
    }),
];
