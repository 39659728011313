import React, { useState } from 'react';
import conditionalClass from '../../../utils/conditional-class';

interface DeckCreateButtonProps {
    createDeck: (name: string, files: File[]) => void;
}

export default function DeckCreateButton(props: DeckCreateButtonProps) {
    const [name, setName] = useState('');

    const deckNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setName(event.target.value);
    };

    const fileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = event.target;
        if(files===null)
            return;
        const filesArray : File[] = []
        for(let index= 0; index<files.length; index++){
            const currentFile = files[index];
            if(currentFile===null) continue;
            filesArray.push(currentFile);
        }
        props.createDeck(name, filesArray);
        setName('');
        event.target.files = null;
        event.target.value = ''
    };

    return (
        <div id="create_deck">
            <input
                type="text"
                id="create_deck_name"
                name="create_deck_name"
                placeholder="New deck name..."
                onChange={deckNameChange}
                value={name}
            />
            <label
                id="confirm_deck_creation"
                className={conditionalClass(name.length <= 0, 'disabled', '')}
            >
                
                <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    multiple
                    onChange={fileChanged}
                />
            </label>
        </div>
    );
}
