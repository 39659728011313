import React, { useContext } from 'react';
import { StateValueWrapperContext } from '../../../modules/record-sheets-module/contexts/StateValueWrapperContext';
import { NumericFormat } from 'react-number-format';

export interface KDHTMLInputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    defaultStringValue?: string;
}

export default function KDHTMLInput(props: KDHTMLInputProps) {
    const { type, id, defaultStringValue } = props;

    const context = useContext(StateValueWrapperContext);

    const getInputValue = (id: string | undefined) => {
        if (context !== null) {
            if (id) {
                if (defaultStringValue)
                    return context.getInputValueWithDefault(
                        id,
                        defaultStringValue
                    );
                return context.getInputValue(id);
            }
        }

        return '';
    };

    const changeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { id, value } = event.target;
        context?.setInputValue(id, value);
    };

    const getCheckBoxValue = (id: string | undefined) => {
        if (context !== null) {
            if (id) {
                return context.getCheckboxValue(id);
            }
        }

        return false;
    };

    const changeCheckboxValue = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { id, checked } = event.target;
        context?.setCheckboxValue(id, checked);
    };

    if (type === 'number') {
        return (
            <NumericFormat
                {...props}
                type="text"
                // @ts-ignore
                value={props.value ? props.value : getInputValue(id)}
                onChange={props.onChange ? props.onChange : changeInputValue}
                
            />
        );
    }

    if (type !== 'checkbox') {
        return (
            <input
                {...props}
                value={props.value ? props.value : getInputValue(id)}
                onChange={props.onChange ? props.onChange : changeInputValue}
            />
        );
    }

    return (
        <input
            {...props}
            checked={props.checked ? props.checked : getCheckBoxValue(id)}
            onChange={props.onChange ? props.onChange : changeCheckboxValue}
        />
    );
}
