import { inject, injectable } from "inversify";
import Deck from "../../models/dod/decks/Deck";
import DeckList from "../../models/dod/decks/DeckList";
import {ErrorMessage}from "../../models/ErrorMessage";
import IDoDRESTClient from "../../rest-clients/IDoDRESTClient";
import IGoogleStorageRESTClient, { UploadProgress, UploadProgressMultiple } from "../../rest-clients/IGoogleStorageRESTClient";
import IDeckService from "../IDeckService";
import IFileNameValidatorService from "../IFileNameValidatorService";

@injectable()
export default class DeckService implements IDeckService{
    @inject('IDoDRESTClient')
    private readonly dodRestClient! : IDoDRESTClient;

    @inject('IFileNameValidatorService')
    private readonly fileNameValidatorService! : IFileNameValidatorService;

    @inject('IGoogleStorageRESTClient')
    private readonly storageClient! : IGoogleStorageRESTClient;
    private readonly sizeToClass : Map<number, string>;

    constructor(){
        this.sizeToClass = new Map<number,string>();
        this.sizeToClass.set(0, 'small');
        this.sizeToClass.set(1, 'medium');
        this.sizeToClass.set(2, 'large');
        this.sizeToClass.set(3, 'encounter');
        this.sizeToClass.set(4, 'knowledge');
    }

    getDeckTextureClass(size : number) : string{
        return this.sizeToClass.get(size)!;
    }

    async getDeckList (page:number) : Promise<DeckList | null>{
        return await this.dodRestClient.getDeckList(page);
    }

    async getDeck (deckId : number) : Promise<Deck|null>{
        return await this.dodRestClient.getDeck(deckId);
    }

    async createDeck(deckName: string, files: File[], uploadProgressCallback: UploadProgressMultiple): Promise<ErrorMessage> {
        if (files.length < 1) {
            return {value:'Incorrect number of textures selected!'};
        }
        for (let i = 0; i < files.length; i++) {
            const { name } = files[i];
            if (this.fileNameValidatorService.validateName(name)) {
                return {value:`Invalid characters in the file name ${name}`};
            }
        }

        await this.dodRestClient.deleteTempFolder();

        

        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            const uploaded = await this.storageClient.uploadTempFile(file, (progress)=>{
                uploadProgressCallback(index,files.length,progress);
            });
            if (!uploaded) {
                console.log('Failed to upload file');
            }
        }

        await this.dodRestClient.uploadDeck(deckName);

        return null;
    }

    async deleteDeck(deckId : number) : Promise<boolean>{
        return await this.dodRestClient.deleteDeck(deckId);
    }

    async updateDeck(deck: Deck) : Promise<ErrorMessage>{
        return await this.dodRestClient.updateDeck(deck);
    }

    async swapTextureCard(
        cardId: number,
        deckId : number,
        side: string,
        file: File,
        uploadProgress : UploadProgress
    ): Promise<ErrorMessage> {
        if (file === null) return {value:'Wrong file'};

        const { name } = file;
        if (this.fileNameValidatorService.validateName(name)) {
            return {value:`Invalid characters in the file name ${name}`};
        }

        
        const destination = `Streaming/Decks/${deckId}/${name}`;
        
        if(await this.storageClient.fileExists(destination)){
            return {value:'File already exists'};
        }

        const uploadStatus = await this.storageClient.uploadFile(destination, file, uploadProgress);

        if(!uploadStatus){
            return {value:'Failed to upload the texture'};
        }

        const dbResponse = await this.dodRestClient.swapCardTexture(cardId, side, name);

        if(!dbResponse){
            return {value:'Failed to swap the texture'};
        }

        return null;
    }
}