import axios, { AxiosRequestConfig } from "axios";
import { inject } from "inversify";
import jwtDecode from "jwt-decode";
import WorkerRequest from "../../models/requests/WorkerRequest";
import { IAuthService } from "../../services/IAuthService";
import IWorkerRequestService from "../../services/IWorkerRequestService";
import IGoogleStorageRESTClient, { UploadProgress } from "../IGoogleStorageRESTClient";
import { RESTClient } from "./RESTClient";

export default class GoogleStorageRESTClient extends RESTClient implements IGoogleStorageRESTClient{
    @inject('IAuthService')
    private readonly authService! : IAuthService;

    @inject('IWorkerRequestService')
    private readonly workerService! : IWorkerRequestService;

    private readonly GOOGLE_STORAGE_URL = "https://storage.googleapis.com/storage/v1/b/";
    private readonly GOOGLE_UPLOAD_BASE_URL = "https://storage.googleapis.com/upload/storage/v1/b/";
    private readonly BUCKET_NAME="database-of-death-bucket";

    constructor(){
        super();
        this.tokenURL = 'auth/storage/token';
        this.setBaseURL('https://wod-dod-uux56memxa-uc.a.run.app/api/v1/')
    }

    async getTextureBase64(objectName: string): Promise<string | null> {
        const token = await this.authService.getToken()
        if (token === null) return null

        const storageToken = await this.getToken(token)

        const url = `${this.GOOGLE_STORAGE_URL}${this.BUCKET_NAME}/o/${encodeURIComponent(objectName)}?alt=media`;

        try{
            const response = await axios.get(url, this.getArrayBufferHeader(storageToken));
            const buffer = response.data as ArrayBuffer;
            return this.arrayBufferToBase64(buffer);
        }
        catch{
            return null;
        }
    }

    
    async uploadTempFile(file: File | null, uploadProgressCallback : UploadProgress): Promise<boolean> {
        if(file===null)
            return true;

        const token = await this.authService.getToken()
        if (token === null) return false

        const storageToken = await this.getToken(token);

        const iss = (jwtDecode(token) as any).iss;
        
        const userBase64 = btoa(iss);

        const destination = "Temp/"+userBase64+"/"+file.name;

        const url = `${this.GOOGLE_UPLOAD_BASE_URL}${this.BUCKET_NAME}/o?uploadType=media&name=${destination}`;

        try{
            await axios.post(url, file, this.getContentTypeHeader(storageToken, file.type, uploadProgressCallback));
            return true;
        }
        catch{
            return false;
        }
    }


    async uploadFile(destination : string,file: File | null, uploadProgressCallback : UploadProgress): Promise<boolean> {
        if(file===null)
            return true;

        const token = await this.authService.getToken()
        if (token === null) return false

        const storageToken = await this.getToken(token);

        const url = `${this.GOOGLE_UPLOAD_BASE_URL}${this.BUCKET_NAME}/o?uploadType=media&name=${destination}`;

        try{
            await axios.post(url, file, this.getContentTypeHeader(storageToken, file.type, uploadProgressCallback));
            return true;
        }
        catch{
            return false;
        }
    }

    async fileExists(destination : string) : Promise<boolean>{
        const token = await this.authService.getToken()
        if (token === null) return false

        const storageToken = await this.getToken(token)

        const url = `${this.GOOGLE_STORAGE_URL}${this.BUCKET_NAME}/o/${encodeURIComponent(destination)}?alt=media`;

        try{
            await axios.get(url, this.getArrayBufferHeader(storageToken));
            return true
        }
        catch{
            return false;
        }
    }


    protected getArrayBufferHeader(token: string): AxiosRequestConfig<any> {
        const config: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            responseType : 'arraybuffer'
        }
        return config
    }

    protected getContentTypeHeader(token: string, contentType : string, progress : UploadProgress): AxiosRequestConfig<any> {
        const config: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type' : contentType
            },
            responseType : 'arraybuffer',
            onUploadProgress : (p : ProgressEvent)=>{progress(p.loaded/p.total)}
        }
        return config
    }

    async getTextureBase64Batch(objectNames: Map<number, string>, callback:(id:number, data:string | null)=>void){
        const token = await this.authService.getToken()
        if (token === null) return null

        const storageToken = await this.getToken(token)

        const requests : WorkerRequest[] = []

        objectNames.forEach((objectName,id)=>{
            const url = `${this.GOOGLE_STORAGE_URL}${this.BUCKET_NAME}/o/${encodeURIComponent(objectName)}?alt=media`;
            
            const request : WorkerRequest = {
                id : id,
                url : url,
                token : storageToken
            };

            requests.push(request);
        })


        this.workerService.reset()

        this.workerService.makeBatchRequest(requests, (response)=>{
            if(response.code>=200 && response.code<300)
            {
                callback(response.id as number, this.arrayBufferToBase64(response.body as ArrayBuffer));
                return;
            }
            callback(response.id as number, null);
        })


        
    }

    private arrayBufferToBase64(buffer : ArrayBuffer) {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    }

}