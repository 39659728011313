import { inject, injectable } from "inversify";
import MenuAction from "../../models/MenuAction";
import MenuItem from "../../models/MenuItem";
import type { IAuthService } from "../IAuthService";
import ILayoutService from "../ILayoutService";

@injectable()
export default class LayoutService implements ILayoutService{
    @inject("IAuthService")
    private authService! : IAuthService;
    getMenuItems = (accessLevel: number) => {
        const items : MenuItem[] = [];
        items.push({
            id:1,
            name:"Record Sheets",
            target_path : "records",
            icon_id : "records",
            access_level : 0
        });
        items.push({
            id:2,
            name:"Decks",
            target_path : "decks",
            icon_id : "decks",
            access_level : 0
        });
        items.push({
            id:3,
            name:"Tokens",
            target_path : "tokens",
            icon_id : "tokens",
            access_level : 0
        });
        items.push({
            id:4,
            name:"Books",
            target_path : "books",
            icon_id : "books",
            access_level : 0
        });
        const returnItems : MenuItem[] = [];
        items.forEach(item=>{
            if(item.access_level<=accessLevel)
                returnItems.push(item)
        })

        return returnItems;

    }
    getActions = () => {
        const items : MenuAction[] = [];

        const returnItems : MenuAction[] = [];

        items.forEach(item=>{
            if(item.access_level<=this.authService.getAccessLevel(item.access_type))
                returnItems.push(item)
        })

        return returnItems;
    }

}