import { useInjection } from 'inversify-react';
import React from 'react';
import Card from '../../../models/dod/decks/Card';
import Deck from '../../../models/dod/decks/Deck';
import IDeckService from '../../../services/IDeckService';
import SwapTexture from '../../general/swap-texture/swap-texture.component';

interface CardComponentProps {
    dataAccess: number;
    card: Card;
    deck: Deck;
    updateCard: (card: Card) => void;
    removeCard : ()=>void;
    swapTexture : (side: string, file: File)=>void;
}

export default function CardComponent(props: CardComponentProps) {
    const { dataAccess, card, deck, updateCard, removeCard, swapTexture } = props;

    const deckService = useInjection<IDeckService>('IDeckService');

    const onSelectedTextureChanged = (newId: number, side : string) => {
        if(side==='front'){
            card.front_id = newId;
            updateCard(card);
        }
        if(side==='back'){
            card.back_id = newId;
            updateCard(card);
        }
    };

    const getInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        return e.target.value;
    };

    const onRemoveCard = (e:React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault();
        removeCard();
    }

    const onSwapTexture = (side: string, file: File) => {
        swapTexture(side, file);
    };

    const classSize = deckService.getDeckTextureClass(deck.size);

    return (
        <div className="card_entry" id={`card_${card.id}`}>
            <div className="card_textures">
                <SwapTexture
                    dataAccess={dataAccess}
                    classSize={classSize}
                    side="front"
                    headerText="Front"
                    texture_id={card.front_id}
                    onSelectedChanged={(id)=>{
                        onSelectedTextureChanged(id, 'front');
                    }}
                    swapTexture={onSwapTexture}
                />
                <SwapTexture
                    dataAccess={dataAccess}
                    classSize={classSize}
                    side="back"
                    headerText="Back"
                    texture_id={card.back_id}
                    onSelectedChanged={(id)=>{
                        onSelectedTextureChanged(id, 'back');
                    }}
                    swapTexture={onSwapTexture}
                />
            </div>
            <div className="card-data">
                <div className="card-data-entry">
                    <span className="label">Name</span>
                    <input
                        type="text"
                        className="card_name"
                        value={card.name}
                        onChange={(e) => {
                            card.name = getInputValue(e);
                            updateCard(card);
                        }}
                    ></input>
                </div>
                <div className="card-data-entry">
                    <span className="label">Quantity</span>
                    <input
                        type="number"
                        className="card_quantity"
                        value={card.quantity}
                        onChange={(e) => {
                            card.quantity = parseInt(getInputValue(e));
                            updateCard(card);
                        }}
                    ></input>
                </div>
            </div>
            {dataAccess >= 2 ? (
                <button type="button" className="remove_card_button" onClick={onRemoveCard}>
                    Remove Card
                </button>
            ) : null}
        </div>
    );
}
