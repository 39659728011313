import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import ITextureRegistry from '../../../services/ITextureRegistry';
import classes from '../../../utils/classes';
import conditionalClass from '../../../utils/conditional-class';

interface SwapTextureProps {
    dataAccess: number;
    side: string;
    classSize: string;
    headerText: string;
    texture_id: number;
    onSelectedChanged : (newId: number)=>void
    swapTexture : (side : string, file : File)=>void;
}

export default function SwapTexture(props: SwapTextureProps) {

    const textureRegistry = useInjection<ITextureRegistry>('ITextureRegistry');

    const { dataAccess, side, headerText, classSize, texture_id: id } = props;

    const [flag,setFlag] = useState(false)

    const resources = [...textureRegistry.getIdToFileNameMap()];

    const onTextureChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        props.onSelectedChanged(parseInt(event.target.value));
    };

    const onSwapTexture = (event : React.ChangeEvent<HTMLInputElement>)=>{
        event.preventDefault();
        var files = event.target.files;
        if(files===null)
            return;
        const file = files[0];
        props.swapTexture(props.side, file);
    }

    const isMissing = textureRegistry.isMissingTexture(id);
    const data = textureRegistry.getTextureDataCallback(id, (data)=>{
        setFlag(!flag)
    });

    let isLoading = false;

    if(!isMissing && data===null && id!==0){
        isLoading = true;
    }

    const loadingClass = conditionalClass(isLoading, 'loading', undefined);
    const missingClass = conditionalClass(isMissing, 'missing', undefined);
    const bgImage = id!==0? `url('data:image/jpeg;charset=utf-8;base64,${data}` : 'none';

    return (
        <div className="texture-entry">
            <span className="texture-facing">{headerText}</span>
            <div
                className={classes(
                    'texture',
                    side,
                    classSize,
                    loadingClass,
                    missingClass
                )}
                style={{backgroundImage:bgImage}}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <select
                className={classes('texture-selection', side)}
                value={id}
                onChange={onTextureChanged}
            >
                {resources.map((r) => {
                    return (
                        <option key={r[0]} value={r[0]}>
                            {r[1]}
                        </option>
                    );
                })}
            </select>
            {dataAccess >= 3 ? (
                <label className={classes('swap_texture_button', side)}>
                    <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        multiple={false}
                        onChange={onSwapTexture}
                    />
                </label>
            ) : null}
        </div>
    );
}
