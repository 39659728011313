import { inject, injectable } from 'inversify';
import type IDoDRESTClient from '../../rest-clients/IDoDRESTClient';
import { IDoDService } from '../IDoDService';
import type { IAuthService } from '../IAuthService';
import ExpansionList from '../../models/expansions/ExpansionList';

@injectable()
export default class DoDService implements IDoDService {
    @inject('IDoDRESTClient')
    private dodRestClient!: IDoDRESTClient;

    @inject('IAuthService')
    private authService!: IAuthService;

    getExpansions(): Promise<ExpansionList> {
        throw new Error('Method not implemented.');
    }
   
}
