import { resolve } from 'inversify-react';
import React from 'react';
import Deck from '../../../models/dod/decks/Deck';
import DeckList from '../../../models/dod/decks/DeckList';
import type { IAuthService } from '../../../services/IAuthService';
import type IDeckService from '../../../services/IDeckService';
import type ITextureRegistry from '../../../services/ITextureRegistry';
import type IUIService from '../../../services/IUIService';
import DefaultListOptionView from '../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../general/list-view/list-view.component';
import DeckCardList from '../deck-card-list/deck-card-list.component';
import DeckCreateButton from '../deck-create-button/deck-create-button.component';
import DeckToolbar from '../deck-toolbar/deck-toolbar.component';
import './deck-directory.styles.scss';

interface DeckDirectoryListState {
    elements: DeckList | null;
    page: number;
    selected: number;
}

interface DeckDirectoryState {
    deckList: DeckDirectoryListState;
    currentSelectedDeck: Deck | null;
    saveEnabled: boolean;
}

export default class DeckDirectory extends React.Component<
    {},
    DeckDirectoryState
> {
    @resolve('IDeckService')
    private readonly deckService!: IDeckService;

    @resolve('IUIService')
    private readonly uiService!: IUIService;

    @resolve('IAuthService')
    private readonly authService!: IAuthService;

    @resolve('ITextureRegistry')
    private readonly textureRegistry!: ITextureRegistry;

    constructor(props: {}) {
        super(props);
        this.state = {
            deckList: {
                selected: -1,
                page: 1,
                elements: null,
            },
            currentSelectedDeck: null,
            saveEnabled: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    async getData(forceSetFirst: boolean = false, reload:boolean = false) {
        this.uiService.showLoading();
        const response = await this.deckService.getDeckList(
            this.state.deckList.page
        );
        if (response === null) {
            this.uiService.hideLoading();
            this.uiService.showErrorNotification('Loading deck list failed!');
            return;
        }
        this.uiService.hideLoading();

        if(!response.items)
            return;

        const setToFirst =response.items.length > 0 && 
            (this.state.deckList.selected === -1 ||
            forceSetFirst);
        
        if (setToFirst && response.items[0]) {
            
            const firstId = response.items[0].id;
            this.onListSelectionChanged(firstId);
            this.setState({
                ...this.state,
                deckList: {
                    ...this.state.deckList,
                    selected: firstId
                }
            });
        } else{
            this.setState({
                ...this.state,
                deckList: {
                    ...this.state.deckList,
                    elements: response
                },
            });
        }
    }

    onListSelectionChanged = async (selectedId: number) => {
        this.uiService.showLoading();
        this.deckService.getDeck(selectedId).then((response) => {
            if (response === null) {
                this.uiService.showErrorNotification('Failed to get deck data');
                return;
            }
            this.textureRegistry.put(response.resources);
            this.textureRegistry
                .loadTexturesFromCloud((fileName) => {
                    return 'Streaming/Decks/' + fileName;
                })
                .then((t) => {
                    if (t) {
                        this.forceUpdate();
                    }
                });

            this.setState({
                ...this.state,
                currentSelectedDeck: response,
                deckList: {
                    ...this.state.deckList,
                    selected: selectedId,
                },
                saveEnabled : false
            });
            this.uiService.hideLoading();
        }).catch(()=>{
            this.uiService.hideLoading();
        })
      

        this.setState({
            ...this.state,
            deckList: {
                ...this.state.deckList,
                selected: selectedId,
            },
        });
    };

    onListPageChanged = (page: number) => {
        this.setState(
            {
                ...this.state,
                deckList: {
                    ...this.state.deckList,
                    page: page,
                },
            },
            () => {
                this.getData();
            }
        );
    };

    onCreateDeck = async (deckName: string, files: File[]) => {
        this.uiService.showLoading();
        const response = await this.deckService.createDeck(deckName, files, (index, total, progress)=>{
            const percentage = (index/total + (1/total)*progress)*100;
            this.uiService.showProgressLoading(percentage, `Uploading ${files[index].name}`);
        });
        this.uiService.hideLoading();

        if(response!=null){
            this.uiService.showErrorNotification(response.value);
            return;
        }else{
            this.uiService.showSuccessNotification('Successfuly created a deck!');
        }

        this.getData(true);
    };

    onSaveDeck =async () => {
        if(this.state.currentSelectedDeck===null)
            return;
        this.uiService.showLoading();
        const response = await this.deckService.updateDeck(this.state.currentSelectedDeck);
        this.uiService.hideLoading();

        if(response===null){
            this.uiService.showSuccessNotification('Deck saved successfully');
            this.getData();
            this.onRefreshDeck();
        }
        else{
            if(response.statusCode===409){
                this.onRefreshDeck();
            }
            this.uiService.showErrorNotification(response.value);
        }
    };

    onRefreshDeck = () => {
        this.onListSelectionChanged(this.state.deckList.selected);
    };

    onDeleteDeck = async () => {
        if(this.state.currentSelectedDeck===null)
            return;
        const confirm = await this.uiService.showConfirmationDialog(`Do you want to delete the deck ${this.state.currentSelectedDeck.name}`, 'No', 'Yes');
        if(!confirm){
            return;
        }
        const deleteResponse = await this.deckService.deleteDeck(this.state.currentSelectedDeck!.id);

        if(!deleteResponse){
            this.uiService.showErrorNotification('Failed to delete the deck');
        }
        else{
            this.uiService.showSuccessNotification('Deck deleted successfuly');
            this.getData(true);
        }
    };

    onDeckUpdated = (deck: Deck) => {
        this.setState({
            ...this.state,
            currentSelectedDeck: deck,
            saveEnabled : true
        });
    };

    
    onSwapTexture = async (cardId:number, side : string,file : File)=>{
        if(this.state.currentSelectedDeck===null) return;

        this.uiService.showProgressLoading(0,'Preparing!');

        const swapped = await this.deckService.swapTextureCard(cardId, this.state.currentSelectedDeck.id, side, file, (progress)=>{
            this.uiService.showProgressLoading(progress*100,null);
        });

        if(swapped!=null){
            this.uiService.showErrorNotification(swapped.value);
        }
        else{
            this.uiService.showSuccessNotification('Texture swapped successfully');
            this.onListSelectionChanged(this.state.currentSelectedDeck.id);
        }

        this.uiService.hideLoading();
    }

    render() {
        return (
            <div className="container deck-directory-container">
                <div style={{ display: 'inline' }}>
                    <div style={{ float: 'left' }}>
                        <h3 style={{ textAlign: 'center' }}>Decks</h3>
                        {this.renderCreateButton()}
                        {this.renderList()}
                    </div>
                    {this.renderCardListForm()}
                </div>
            </div>
        );
    }

    renderCreateButton = () => {
        const dataAccess = this.authService.getAccessLevel('data');

        if (dataAccess < 1) return null;

        return <DeckCreateButton createDeck={this.onCreateDeck} />;
    };

    renderList = () => {
        if (this.state.deckList.elements === null) {
            return null;
        }
        const { elements, page, selected } = this.state.deckList;
        const newElements = new DeckList();
        newElements.items = elements.items;
        newElements.max_results = elements.max_results;
        return (
            <ListViewComponent
                elements={newElements}
                page={page}
                selectedValue={selected}
                selectionChanged={this.onListSelectionChanged}
                pageChanged={this.onListPageChanged}
                containerId="deck-list"
                listId="decks"
                listClass="deck_list"
                listElement={(e) => {
                    return (
                        <DefaultListOptionView
                            key={e.id}
                            id={e.id}
                            value={e.name}
                        />
                    );
                }}
            />
        );
    };

    renderCardListForm = () => {
        if (this.state.currentSelectedDeck === null) return;
        return (
            <form id="card-list-form">
                <div style={{ textAlign: 'center', minWidth: '1140px' }}>
                    <h3>Deck Info</h3>
                    <DeckToolbar
                        saveDeck={this.onSaveDeck}
                        refreshDeck={this.onRefreshDeck}
                        deleteDeck={this.onDeleteDeck}
                        saveEnabled={this.state.saveEnabled}
                    />
                    <DeckCardList
                        deck={this.state.currentSelectedDeck}
                        update={this.onDeckUpdated}
                        swapCardTexture={this.onSwapTexture}
                    />
                </div>
            </form>
        );
    };
}
