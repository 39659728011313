import { useInjection } from 'inversify-react';
import React from 'react';
import Deck from '../../../models/dod/decks/Deck';
import { IAuthService } from '../../../services/IAuthService';
import CardComponent from '../card-component/card.component';

interface CardListProps {
    deck: Deck;
    updatedListElement : (deck : Deck)=>void;
    swapTexture:(cardId:number, side:string, file:File)=>void;
}

export default function CardList(props: CardListProps) {
    const authService = useInjection<IAuthService>('IAuthService');

    const dataAccess = authService.getAccessLevel('data');

    const { deck, updatedListElement, swapTexture } = props;

    const onAddNewCard = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        deck.cards.push({
            id : 0,
            back_id: 0,
            front_id :0,
            name : '',
            quantity:1,
            deck_id : deck.id
        });
        updatedListElement(deck);
    };

    return (
        <React.Fragment>
            <h3>Card List</h3>
            <div className="card_list_container">
                <div className="card_list" id="card_list">
                    {deck.cards.map((card, index) => {
                        return (
                            <CardComponent
                                key={card.id}
                                card={card}
                                deck={deck}
                                dataAccess={dataAccess}
                                updateCard={(c)=>{
                                    deck.cards[index]= c;
                                    updatedListElement(deck);
                                }}
                                removeCard={()=>{
                                    deck.cards.splice(index, 1);
                                    updatedListElement(deck);
                                }}
                                swapTexture={(side, file)=>{
                                    swapTexture(card.id, side, file);
                                }}
                            />
                        );
                    })}
                </div>
                {dataAccess >= 3 ? (
                    <input
                        type="button"
                        className="new_card"
                        onClick={onAddNewCard}
                        value="New Card"
                    ></input>
                ) : null}
            </div>
        </React.Fragment>
    );
}
