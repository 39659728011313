import React from "react";

interface TokenHeaderProps {
    saveEnabled: boolean;
    refresh: () => void;
    save: () => void;
    delete: () => void;
    dataAccess : number;
}

export default function TokenHeader(props : TokenHeaderProps) {
    const {dataAccess, saveEnabled } = props;
    return (
        <div className="toolbar">
            <button
                type="button"
                id="refresh_token_button"
                className="refresh_button"
                onClick={(e) => {
                    e.preventDefault();
                    props.refresh();
                }}
            >
                {' '}
            </button>
            {dataAccess >= 2 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        type="button"
                        id="save_token_button"
                        className="save_button"
                        onClick={(e) => {
                            e.preventDefault();
                            props.save();
                        }}
                        disabled={!saveEnabled}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : null}
            {dataAccess >= 3 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        type="button"
                        id="delete_token_button"
                        className="delete_button"
                        onClick={(e) => {
                            e.preventDefault();
                            props.delete();
                        }}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : null}
        </div>
    );
}
