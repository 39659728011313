import { Overlay } from '../overlay/overlay.component';
import './confirm-dialog.styles.scss';
import store, { RootState } from '../../redux/store';
import React from 'react';
import { setResponse } from '../../redux/slices/confirmDialogSlice';
import { useSelector } from 'react-redux';

export default function ConfirmDialog() {
    const confirm = useSelector((state:RootState)=>state.confirmDialog.open);
    const prompt = useSelector((state:RootState)=>state.confirmDialog.prompt);
    const negativeButton = useSelector((state:RootState)=>state.confirmDialog.negativeButton);
    const positiveButton = useSelector((state:RootState)=>state.confirmDialog.positiveButton);

    const buttonClick = (value: boolean) => {
        store.dispatch(setResponse(value));
    };

    return (
        <Overlay open={confirm}>
            <div id="wrapper">
                <div className="popup">
                    <h4>Confirm</h4>
                    <p>{prompt}</p>
                    <div className="button-container">
                        <button
                            className="btn btn-cancel"
                            onClick={(e) => {
                                e.preventDefault();
                                buttonClick(false);
                            }}
                        >
                            {negativeButton}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                buttonClick(true);
                            }}
                        >
                            {positiveButton}
                        </button>
                    </div>
                </div>
            </div>
        </Overlay>
    );
}
