export default function renderFor<T>(
    from: number,
    to: number,
    callback: (index: number) => T
): T[] {
    const array: T[] = [];

    for (let i = from; i < to; i++) {
        array[i - from] = callback(i);
    }
    return array;
}

export function renderForEach<T, K>(
    source: K[],
    callback: (element: K, index: number) => T
): T[] {
    const array: T[] = [];
    const from = 0;
    const to = source.length;
    for (let i = from; i < to; i++) {
        array[i - from] = callback(source[i], i);
    }
    return array;
}
