import React from 'react';
import Book from '../../../models/dod/books/Book';
import BookForm from '../book-form/book-form.component';
import BookInfo from '../book-info/book-info.component';

export interface BookFormContainerProps {
    book: Book;
    update: (book: Book) => void;
    swapBookCover: (side: string, file: File) => void;
    swapPage: (pageId: number, file: File) => void;
    formPageNumber: number;
    setFormPageNumber: (page: number) => void;
}

export default function BookFormContainer(props: BookFormContainerProps) {
    const { book, update, swapBookCover, swapPage ,formPageNumber, setFormPageNumber} = props;

    const renderInfo = () => {
        return <BookInfo book={book} update={update} />;
    };

    const getImagePath = (id: number) => {
        const resource = book.resources.find((x) => x.id === id);
        if (resource === undefined) {
            return null;
        }

        return `Streaming/Books/${resource.resource_path}`;
    };

    const renderPages = () => {
        return (
            <BookForm
                book={book}
                update={update}
                swapBookCover={swapBookCover}
                swapPage={swapPage}
                getImagePath={getImagePath}
                pageNumber = {formPageNumber}
                setPageNumber = {setFormPageNumber}
            />
        );
    };

    return (
        <React.Fragment>
            {renderInfo()}
            {renderPages()}
        </React.Fragment>
    );
}
