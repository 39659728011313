import React, { useState } from "react";

interface BookPageToolbarProps{
    page : number,
    totalPages : number,
    changePage : (page : number)=>void;
}

export default function BookPageToolbar(props:BookPageToolbarProps){
    const {page, changePage, totalPages} = props;

    const [navPage, setNavPage] = useState('');

    const navPageChanged = (e:React.ChangeEvent<HTMLInputElement>)=>{
        e.preventDefault();
        let {value} = e.target;
        let valueInt = parseInt(value);

        if(valueInt<0){
            valueInt = 0;
        }

        if(valueInt>=totalPages){
            valueInt = totalPages;
        }

        if(Number.isNaN(valueInt)){
            value = '';
        }else{
            value = valueInt.toString();
        }

        setNavPage(value);
    }

    return (
        <div className="toolbar navigation">
                <input
                    type="button"
                    id="previous_page"
                    className="previous_page"
                    onClick={e=>{
                        e.preventDefault();
                        changePage(page-2);
                    }}
                    disabled={page<2}
                ></input>
                <div className="page-jump-container">
                    <label htmlFor="male">Go to Page</label>
                    <input
                        type="number"
                        name="jump_page"
                        id="jump_page"
                        value={navPage}
                        onChange={navPageChanged}
                    ></input>
                    <input
                        type="button"
                        name="jump"
                        id="jump"
                        value="GO"
                        disabled = {navPage===''}
                        onClick={e=>{
                            e.preventDefault();
                            let navPageInt = parseInt(navPage);
                            navPageInt-=1;
                            navPageInt/=2;
                            navPageInt = Math.floor(navPageInt);
                            navPageInt*=2;
                            changePage(navPageInt);
                        }}
                    ></input>
                </div>
                <input
                    type="button"
                    id="next_page"
                    className="next_page"
                    onClick={e=>{
                        e.preventDefault();
                        changePage(page+2);
                    }}
                    disabled={(totalPages - page) <= 2}
                ></input>
            </div>
    );
}